<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <h3>{{ title }}</h3>
            <div class="modal-default-button" @click="$emit('closeModal')">
              <span class="ico-close"></span>
            </div>
          </div>

          <div class="modal-body" v-html="embedHtml()"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'WebcamModal',
  props: ['webcam'],
  computed: {
    title() {
      return this.webcam.Title;
    },
    src() {
      return this.webcam.ThumbnailUrl;
    },
    fullUrl() {
      return this.webcam.FullImageUrl;
    },
    iframeUrl() {
      return this.webcam.IframeUrl;
    },
  },
  methods: {
    embedHtml() {
      // var html = '';
      const cache_breaker = `?=${Math.random()}`;
      // This is a dirty way to do this
      if (this.webcam.IframeUrl) {
        return `<iframe id="webcam" scrolling="no" src="${this.iframeUrl}?autoplay=1" marginwidth="0" height="700" frameborder="0" width="100%" title="${this.title} Webcam"></iframe>`;
      }
      return `<img src="${this.fullUrl}" alt="${this.title}" />`;
    },
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) {
        this.$emit('closeModal');
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-default-button {
  color: $white;
}
</style>
