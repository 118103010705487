<template>
  <div class="trail-icons">
    <div class="trail-icon icon--grooming" v-if="showGrooming">
      <img :src="require(`@/assets/icons/dark/grooming_${groomingIcon}.svg`)" alt="Grooming Icon" class="icon" />
    </div>
    <div class="trail-icon icon--snowmaking" v-if="showSnowmaking">
      <img :src="require(`@/assets/icons/dark/snowmaking_${snowmakingIcon}.svg`)" alt="snowmaking Icon" class="icon" />
    </div>
    <div class="trail-icon icon--glades" v-if="showGlades">
      <img :src="require(`@/assets/icons/dark/glades_${gladesIcon}.svg`)" alt="Glades Icon" class="icon" />
    </div>
    <div class="trail-icon icon--moguls" v-if="showMoguls">
      <img :src="require(`@/assets/icons/dark/moguls_${mogulsIcon}.svg`)" alt="Moguls Icon" class="icon" />
    </div>
    <div class="trail-icon icon--touring" v-if="showGroomersChoice">
      <img :src="require(`@/assets/icons/dark/groomers_choice.svg`)" alt="Groomer's Choice Icon" class="icon" />
    </div>
    <div class="trail-icon icon--touring" v-if="showTouring">
      <img :src="require(`@/assets/icons/dark/${touringIcon}.svg`)" alt="Touring Icon" class="icon" />
    </div>
    <div class="trail-icon icon--nordic" v-if="showNordic">
      <img :src="require(`@/assets/icons/dark/nordic_${nordicIcon}.svg`)" alt="Nordic Icon" class="icon" />
    </div>
    <div class="trail-icon icon--nightskiing" v-if="showNightskiing">
      <img :src="require(`@/assets/icons/dark/nightskiing_${nightSkiingIcon}.svg`)" alt="NightSkiing Icon" class="icon" />
    </div>
    <div class="trail-icon icon--race-in-progress" v-if="showRaceInProgress">
      <img :src="require(`@/assets/icons/dark/${raceInProgressIcon}`)" alt="Race In Progress Icon" class="icon" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrailIcons',
  props: ['trail'],
  computed: {
    ...mapGetters(['filterIcons', 'customIcons', 'resortSluggedName']),
    groomingIcon() {
      return this.trail.Grooming.toLowerCase().split(' ').join('_').replace('grooming_', '');
    },
    snowmakingIcon() {
      return this.trail.SnowMaking.toLowerCase().split(' ').join('_').replace('snowmaking_', '');
    },
    gladesIcon() {
      return this.trail.Glades.toLowerCase().split(' ').join('_').replace('glades_', '');
    },
    mogulsIcon() {
      return this.trail.Moguls.toLowerCase().split(' ').join('_').replace('moguls_', '');
    },
    touringIcon() {
      const touringIcon = this.trail.Touring.toLowerCase().split(' ').join('_').replace('touring_', '')

      if( this.customIcons.indexOf('touring') >= 0 ) {
        return `resorts/${this.resortSluggedName}/touring_${touringIcon}`;
      }

      return `touring_${touringIcon}`;
    },
    nordicIcon() {
      return this.trail.Nordic.toLowerCase().split(' ').join('_').replace('nordic_', '');
    },
    nightSkiingIcon() {
      return this.trail.NightSkiing.toLowerCase().split(' ').join('_').replace('nightskiing_', '');
    },
    raceInProgressIcon() {
      return 'race_in_progress.svg';
    },
    showGrooming() {
      return this.filterIcons.grooming && (this.trail.Grooming && !/No/i.test(this.trail.Grooming));
    },
    showSnowmaking() {
      return this.filterIcons.snowmaking && (this.trail.SnowMaking && !/No/i.test(this.trail.SnowMaking));
    },
    showMoguls() {
      return this.filterIcons.moguls && (this.trail.Moguls && !/No/i.test(this.trail.Moguls));
    },
    showGlades() {
      return this.filterIcons.glades && (this.trail.Glades && !/No/i.test(this.trail.Glades));
    },
    showNightskiing() {
      return this.filterIcons.nightskiing && (this.trail.NightSkiing && !/No/i.test(this.trail.NightSkiing));
    },
    showTouring() {
      return this.filterIcons.touring && (this.trail.Touring && /Yes/i.test(this.trail.Touring));
    },
    showGroomersChoice() {
      return this.trail.StatusEnglish === 'groomers_choice';
    },
    showNordic() {
      return this.filterIcons.nordic && (this.trail.Nordic && /Yes/i.test(this.trail.Nordic));
    },
    showRaceInProgress() {
      return /closed_race_in_progress/i.test(this.trail.StatusEnglish);
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .trail-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    .trail-icon {
      display: block;
      vertical-align: middle;
      width: auto;

      .icon {
        display: block;
        fill: $dark-gray;
        height: auto;
        max-width: initial;
        position: relative;
        width: 50px;
      }
      &.icon--grooming {
        .icon {
        }
      }
      &.icon--glades {
        .icon {
        }
      }
      &.icon--moguls {
        .icon {
        }
      }
      &.icon--touring {
        .icon {
        }
      }
      &.icon--nightskiing {
        .icon {
        }
      }
      &.icon--race-in-progress {
        .icon {
        }
      }
    }
  }
  .closed-trail {
    .trail-icons {
      display: none;
    }
  }
}
</style>
