<template>
  <span class="weather-icon" :class="weatherIcon"></span>
</template>

<script>
export default {
  name: 'WeatherIcon',
  props: ['weatherZone'],
  data() {
    return {
      defaultWeatherIcon: 'ico-unknown',
    };
  },
  computed: {
    weatherIcon() {
      if (this.weatherZone.Conditions) {
        return `ico-${this.weatherZone.Conditions.replace(/\_/g, '')}`;
      }
      return this.defaultWeatherIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
  .weather-icon {
    font-size: 80px;
    position: relative;
  }
</style>
