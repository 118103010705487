<template>
  <div class="navigation">
    <ul class="flex-grid flex-grid--thirds">
      <li class="col ttu">
        <a href="#" :class="{ 'active': activeDashboardSection == 'Weather'}" @click.prevent="setDashboardSection('Weather')">
          Weather
        </a>
      </li>
      <li class="col ttu">
        <a href="#" :class="{ 'active': activeDashboardSection == 'Trails'}" @click.prevent="setDashboardSection('Trails')">
          {{ trailsOverviewTitle }}
        </a>
      </li>
      <li class="col ttu">
        <a href="#" :class="{ 'active': activeDashboardSection == 'Cams'}" @click.prevent="setDashboardSection('Cams')">
          Cams
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MobileNavigation',
  computed: mapState(['activeDashboardSection', 'trailsOverviewTitle']),
  methods: {
    setDashboardSection(section) {
      this.$store.commit('SET_ACTIVE_DASHBOARD_SECTION', section);
    },
  },
};
</script>

<style scoped lang="scss">
.navigation {
  background-color: $dark-gray;
  height: 80px;
  padding: 15px 20px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
  @media (min-width: $tablet) {
    display: none;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      text-align: center;
      a {
        color: $white;
        display: inline-block;
        font-family: $headline-font-bold;
        font-size: 14px;
        font-weight: $headline-font-weight;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.14;
        letter-spacing: 0.37px;
        position: relative;
        text-decoration: none;
        width: auto;
        &:after {
          background-color: transparent;
          transition: background-color 0.25s ease-in-out;
        }
        &.active {
          &:after {
            background-color: $mobile-nav-active;
            content: '';
            display: block;
            height: 4px;
            position: absolute;
            left: 50%;
            margin-left: -25%;
            top: 35px;
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
