<template>
  <div class="current-weather">
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ sinceLiftsClosed }}
            <span class="unit" v-if="snowbaseUnit === 'metric'">CM</span>
            <span class="unit" v-else>IN</span>
          </span>
        </div>
        <div class="skies-text">Overnight</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ snow24Hours }}
            <span class="unit" v-if="snowbaseUnit === 'metric'">CM</span>
            <span class="unit" v-else>IN</span>
          </span>
        </div>
        <div class="skies-text">24 Hours</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ snow7Days }}
            <span class="unit" v-if="snowbaseUnit === 'metric'">CM</span>
            <span class="unit" v-else>IN</span>
          </span>
        </div>
        <div class="skies-text">7 Days</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ snowBase }}
            <span class="unit" v-if="snowbaseUnit === 'metric'">CM</span>
            <span class="unit" v-else>IN</span>
          </span>
        </div>
        <div class="skies-text">Base</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ seasonTotal }}
            <span class="unit" v-if="snowbaseUnit === 'metric'">CM</span>
            <span class="unit" v-else>IN</span>
          </span>
        </div>
        <div class="skies-text">Season Total</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'Conditions',
  props: ['currentArea'],
  components: { },
  methods: {
    roundValue(val) {
      if (this.weatherRoundValues == true) {
        return Math.ceil(val * 1.0);
      }
      return val;
    }
  },
  computed: {

    seasonTotal() {
      if (!this.currentArea) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.snow_report.SeasonTotalCm.toString().split('.')[0]
          : this.snow_report.SeasonTotalIn.toString().split('.')[0];
      }
    },
    // seasonTotal() {
    //   if (!this.snow_report) { return; }
    //
    //   return this.snowbaseUnit === 'metric'
    //     ? `${this.roundValue(this.snow_report.SeasonTotalCm)}`
    //     : `${this.roundValue(this.snow_report.SeasonTotalIn)}`;
    // },

    sinceLiftsClosed() {
      if (!this.currentArea) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.SinceLiftsClosedCm.toString().split('.')[0]
          : this.currentArea.SinceLiftsClosedIn.toString().split('.')[0];
      }
    },
    snow24Hours() {
      if (!this.currentArea) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.Last24HoursCm.toString().split('.')[0]
          : this.currentArea.Last24HoursIn.toString().split('.')[0];
      }
    },
    snow48Hours() {
      if (!this.currentArea) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.Last48HoursCm.toString().split('.')[0]
          : this.currentArea.Last48HoursIn.toString().split('.')[0];
      }
    },
    snow72Hours() {
      if (!this.currentArea) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.Last72HoursCm.toString().split('.')[0]
          : this.currentArea.Last72HoursIn.toString().split('.')[0];
      }
    },
    snow7Days() {
      if (!this.currentArea) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.Last7DaysCm.toString().split('.')[0]
          : this.currentArea.Last7DaysIn.toString().split('.')[0];
      }
    },
    snowBase() {
      if (!this.currentArea) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.BaseCm.toString().split('.')[0]
          : this.currentArea.BaseIn.toString().split('.')[0];
      }
    },
    ...mapState(['weather', 'windspeedUnit', 'temperatureUnit', 'snow_report', 'snowbaseUnit']),
    ...mapGetters(['weatherRoundValues']),
  },
};
</script>

<style lang="scss" scoped>
#dashboard {

  .current-weather {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;

    .forecast-day {
      flex: 0 25%;
      min-width: 150px;
      width: 20%;
      border-left: .5px solid $brand-blue;
      color: $brand-blue;
      font-size: 12px;
      padding: 15px 20px;
      text-align: left;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 15px;
      }

      &:first-child {
        border-left: 0;
      }

      p {
        color: $brand-blue;
        letter-spacing: -0.3px;
        line-height: 1.2;
        font-size: 12px;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 15px;
        }
      }
    }

  }
  .weather-details{
    font-size: 50px;
    color: $brand-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    //flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    @media screen and (min-width: $tablet) {
      font-size: 65px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 80px;
    }

    .temperature,
    .weather-icon {
      flex: 0 100%;
      width: 100%;
      height: 50px;
      text-align: center;

      @media screen and (min-width: $tablet) {
        height: 65px;

      }
      @media screen and (min-width: $tablet-landscape) {
        height: 80px;
      }
    }
    .weather-icon {
      font-size: 50px;
      font-weight: 400;
      line-height: 1;
      text-align: center;

      @media screen and (min-width: $tablet) {
        font-size: 65px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 80px;
      }
    }

    .temperature {
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: $tablet) {
        font-size: 40px;
        height: 65px;

      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 50px;
        height: 80px;
      }

      .metric {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 5px;
      }

      .unit {
        margin: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;

        @media screen and (min-width: $tablet) {
          font-size: 18px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }


  .day-word {
    color: $brand-blue;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
  }

  .day-temps,
  .wind-speed-text {
    color: $brand-blue;
    font-size: 10px;
    line-height: 1;
    margin: 5px 0;


    @media screen and (min-width: $tablet) {
      font-size: 13px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 14px;
    }
  }

  .skies-text {
    font-family: $font-sans;
    color: $brand-blue;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0px;

    @media screen and (min-width: $tablet) {
      font-size: 13px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 14px;
    }
  }
}
</style>
