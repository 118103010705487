<template>
  <div class="webcam">
    <div class="title">
      <h2 class="webcam-title ttu">{{ title }}</h2>
    </div>
    <div class="webcam-wrapper">
      <div v-html="iframe_url" v-if="iframe_url"></div>
      <div v-else>
        <img :src="full_url">
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Webcam',
  props: ['webcam'],
  computed: {
    linkId() {
      return `${this.webcam.Title.replace(' ', '-')}-webcam-mobile`;
    },
    title() {
      return this.webcam.Title;
    },
    altText() {
      return `View of conditions at ${this.webcam.Title}`;
    },
    src() {
      return this.webcam.ThumbnailUrl;
    },
    iframe_url() {
      return this.webcam.IframeUrl;
    },
    full_url() {
      return this.webcam.FullImageUrl;
    },
  },
  methods: {
    // openModal(event) {
    //   this.$emit('showWebcamModal', this);
    // },
  },
};
</script>

<style lang="scss" scoped>
.webcam {
  display: block;
  height: auto;
  padding: 20px;
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden;

  .webcam-wrapper {
    position: relative;
  }

  a {
    color: $brand-blue;
    display: block;
    font-family: $base-font-bold;
    font-weight: $headline-font-weight;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    margin: 0;
  }
  .webcam-title {
    color: $brand-blue;
    display: block;
    font-family: $base-font-bold;
    font-weight: $headline-font-weight;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
</style>
