<template>
  <div>
    <div class="mountain-section maps">
      <ul class="map-toggle" v-for="(maps, key) in getMaps">
        <li v-for="(map, index) in maps" :class="{ active : (activeMap === index)}">
          <a href="#" @click.prevent="setMap(index)" class="toggle" >{{ map.name }}</a>
        </li>
      </ul>
    </div>

    <template v-for="(maps, key) in getMaps">
      <Map class="dashboard-block" v-for="(map, index) in maps" :index="index" :map="map" :key="map.name" :active="activeMap"></Map>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Map from '@/components/MountainAreas/Map.vue';

export default {
  name: 'Maps',
  components: {
    Map,
  },
  data() {
    return {
      activeMap: 0,
    };
  },
  computed: {
    ...mapGetters(['getMaps']),
  },
  methods: {
    setMap(value){
      this.activeMap = value;
      //"value" is the clicked button value
    },
  },
};
</script>

<style lang="scss" scoped>
  #dashboard {
    .mountain-section {
      //@media (max-width: $tablet) {
      //  .flex-grid, .flex-grid--halfs {
      //    display: block;
      //    width: 100%;
      //    .col {
      //      display: block;
      //      flex-basis: 100%;
      //      width: 100%;
      //    }
      //  }
      //}
      ul.map-toggle {
        display: flex;
        padding: 0;
        margin: 10px 0 30px;

        @media screen and (min-width: $tablet) {
          margin: 0 0 30px;
        }

        li {
          display: block;
          letter-spacing: 0.85px;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
          vertical-align: baseline;
          border: .5px solid $brand-blue;
          background: #fff;

          &:not(:first-child){
            margin-left: -1px;
          }

          &.active {
            background: $brand-blue;

            a {
              color: $white;
            }
          }

          a {
            display: block;
            color: $brand-blue;
            font-family: $base-font-bold;
            font-weight: $headline-font-weight;
            text-align: center;
            font-size: 12px;
            line-height: 1;
            padding:  12px 8px;

            &:hover,
            &:focus{
              background:$brand-blue3 !important;
              color: $brand-blue !important;
            }

            @media screen and (min-width: $tablet) {
              font-size: 14px;
              padding: 14px 8px;
            }

            @media screen and (min-width: $tablet-landscape) {
              font-size: 14px;
              padding: 16px 14px;
            }

          }
          &:first-child {
            border: .5px solid $brand-blue;
          }
        }
      }
    }
  }
</style>
