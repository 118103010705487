<template>
  <div class="webcams-container">
    <div class="titles">
      <h1 class="title">Webcams</h1>
    </div>
    <div class="webcams-animate-container">
      <div class="webcams" :style="translateStyle">
        <Webcam v-for="webcam in webcams" :webcam="webcam" :key="webcam.Title"></Webcam>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Webcam from './Webcams/Webcam.vue';
import WebcamModal from './Webcams/WebcamModal.vue';

export default {
  name: 'Webcams',
  data() {
    return {
      container: null,
      camSlide: null,
      currentWebcam: null,
      showModal: false,
      translateY: 0,
    };
  },
  components: {
    Webcam,
    WebcamModal,
  },
  computed: {
    ...mapGetters(['webcams']),
    translateStyle() {
      return `transform: translateY(${this.translateY}px)`;
    },
    // hideScrollDown() {
    //   if (this.webcams && this.webcams.size < 4) { return true; }
    //   if (!this.camSlide) { return false; }
    //   if (this.camSlide.scrollHeight == 0) { return false; }
    //
    //   return this.container
    //     && Math.abs(this.translateY) + 100 >= this.camSlide.scrollHeight / 1.5;
    // },
    // hideScrollUp() {
    //   return this.translateY == 0 || this.webcams.length < 4;
    // },
  },
  methods: {
    // showWebcamModal(vueEvent) {
    //   this.currentWebcam = vueEvent.webcam;
    //   this.showModal = true;
    // },
    // closeModal() {
    //   this.showModal = false;
    // },
    // scrollUp() {
    //   this.translateY = this.translateY + (this.camSlide.scrollHeight / 3);
    //   if (this.translateY > 0) { this.translateY = 0; }
    // },
    // scrollDown() {
    //   this.translateY = this.translateY - (this.camSlide.scrollHeight / 3);
    //
    //   if (Math.abs(this.translateY) > this.camSlide.scrollHeight / 1.5) {
    //     this.translateY = 0;
    //   }
    // },
    reflow() {
      // TODO: once this works, we can call reflow on a window resize event
      this.translateY = 0;
      this.container = document.querySelector('.webcams-container');
      this.camSlide = document.querySelector('.webcams');
    },
  },
  mounted() {
    this.reflow();
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .webcams-container {
    background-color: $white;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    width: 100%;
    border: .5px solid $brand-blue;


    .titles {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1.title {
      color: $brand-blue;
      display: block;
      font-family: $base-font-bold;
      font-weight: $headline-font-weight;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 1.2;
      margin: 0;
    }

    //.webcams-animate-container {
    //  overflow: hidden;
    //  max-height: 100%;
    //
    //  //.webcams {
    //  //  font-family: $base-font-regular;
    //  //  font-size: 16px;
    //  //  transition: all ease-in-out 0.5s;
    //  //  @media (max-width: $tablet-landscape) {
    //  //    transform: translateY(0) !important
    //  //  }
    //  //}
    //}
  }
}
</style>
