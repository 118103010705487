<template>
  <div class="trails-overview">
    <div class="trails-details">

      <div class="data-block main-block">
        <div class="block-text"  v-if="totalOpenLifts">
          {{ totalOpenLifts.length }}
        </div>
        <div class="block-title" v-if="totalOpenLifts">
          {{ returnLabel }}
            <!--<span v-if="timeHour >= 16 || timeHour < 9" tabindex="-1">
              {{ trailsOverviewNames["Scheduled-Lifts"] }}
            </span>
            <span v-else tabindex="-1">
              {{ trailsOverviewNames["Lifts"] }}
            </span> -->
        </div>
      </div>

      <div class="data-block main-block">
        <div class="block-text" v-if="totalOpenTrails">
          {{ totalOpenTrails }}
        </div>
        <div class="block-title" v-if="totalOpenTrails">
            <span tabindex="-1">
              {{ trailsOverviewNames["Trails"] }}
            </span>
        </div>
      </div>

      <div class="data-block main-block" v-if="showAttractionsBlock">
        <div class="block-text">
          {{ openActivities }}
        </div>
        <div class="block-title">
            <span tabindex="-1">{{ attractionsText }}</span>
        </div>
      </div>

      <div class="data-block main-block">
        <div class="block-text" v-if="totalGroomedTrails">
          {{ totalGroomedTrails }}
        </div>
        <div class="block-title" v-if="totalGroomedTrails">
          <span>{{ trailsOverviewNames["Groomed"] }}</span>
        </div>
      </div>

      <div class="data-block main-block">
        <div class="block-text"  v-if="totalOpenTerrainParks">
          {{ totalOpenTerrainParks }}
        </div>
        <div class="block-title"  v-if="totalOpenTerrainParks">
          <span>{{ trailsOverviewNames["Terrain-Parks"] }}</span>
        </div>
      </div>
    </div>
    <News v-if="newsPresent" :news="snow_report.Report" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import News from "@/components/News.vue";
import { convertToTimeZone } from 'date-fns-timezone';

let pstDate = convertToTimeZone(new Date(), { timeZone: 'America/Los_Angeles' });
let day = pstDate.getDay();
let hour = pstDate.getHours();

export default {
  components: {
    News,
  },
  name: "TrailsOverview",
  props: ["snow_report"],
  computed: {
    ...mapState([
      "lifts",
      "excludeLiftsFromCount",
      "openStatuses",
      "scheduledStatuses",
      "closedStatuses",
      "holdStatuses",
      "trailsOverviewTitle",
      "attractionsText",
      "trailsOverviewBlocks",
      "trailsOverviewNames"
    ]),

    timeHour(){
      let pstDate = convertToTimeZone(new Date(), { timeZone: 'America/Los_Angeles' });
      //console.log(pstDate.getHours());
      return pstDate.getHours();
    },
    returnLabel(){
      let label = '';

      if(day === 0 || day === 6){
        if(hour >= 16 || hour < 9){
          label = this.trailsOverviewNames["Scheduled-Lifts"];
        } else {
          label = this.trailsOverviewNames["Lifts"];
        }
      } else {
        if(hour >= 16 || hour < 9){
          label = this.trailsOverviewNames["Scheduled-Lifts"];
        } else {
          label = this.trailsOverviewNames["Lifts"];
        }
      }
      return label;
    }
    ,showTrailsFirst() {
      return this.trailsOverviewBlocks.indexOf("TrailsInfo") === 0;
    },
    showLiftsFirst() {
      return this.trailsOverviewBlocks.indexOf("LiftsInfo") === 0;
    },
    showAcresOpenBlock() {
      return this.trailsOverviewBlocks.indexOf("AcresOpenInfo") >= 0;
    },
    showTerrainParksOpenBlock() {
      return this.trailsOverviewBlocks.indexOf("TerrainParksInfo") >= 0;
    },
    showAttractionsBlock() {
      return this.trailsOverviewBlocks.indexOf("ActivitiesInfo") >= 0;
    },
    showGroomedBlock() {
      return this.trailsOverviewBlocks.indexOf("GroomedInfo") >= 0;
    },
    openActivities() {
      if (this.snow_report.TotalOpenActivities) {
        return this.snow_report.TotalOpenActivities;
      }
      return "0";
    },
    totalActivities() {
      if (this.snow_report.TotalActivities) {
        return this.snow_report.TotalActivities;
      }
      return "0";
    },
    totalOpenTrails() {
      if (this.lifts) {
        return this.snow_report.TotalOpenTrails;
      }
      return 0;
    },
    totalTrails() {
      if (this.snow_report.TotalTrails) {
        return this.snow_report.TotalTrails;
      }
      return 0;
    },
    totalOpenAcres() {
      if (this.snow_report.OpenTerrainAcres !== "--") {
        return Math.round(this.snow_report.OpenTerrainAcres);
      }
      return 0;
    },
    totalAcres() {
      if (this.snow_report.TotalTerrainAcres) {
        return Math.round(this.snow_report.TotalTerrainAcres);
      }
      return 0;
    },
    totalOpenHectares() {
      if (this.snow_report.OpenTerrainHectares) {
        return this.snow_report.OpenTerrainHectares;
      }
      return 0;
    },
    totalHectares() {
      if (this.snow_report.TotalTerrainHectares) {
        return this.snow_report.TotalTerrainHectares;
      }
      return 0;
    },
    totalOpenLifts() {
      if (this.lifts) {
        const filteredLifts = this.lifts.filter(
          lift => !this.excludeLiftsFromCount.includes(lift.Name),
        );

        if (day === 0 || day === 6) {
          if (hour >= 16 || hour < 9) {
            return filteredLifts.filter(
              filteredLift => this.scheduledStatuses.includes(filteredLift.StatusEnglish) || this.holdStatuses.includes(filteredLift.StatusEnglish),
            );
          } else {
            return filteredLifts.filter(
              filteredLift => this.openStatuses.includes(filteredLift.StatusEnglish),
            );
          }
        } else {

          if (hour >= 16 || hour < 9) {
            return filteredLifts.filter(
              filteredLift => this.scheduledStatuses.includes(filteredLift.StatusEnglish) || this.holdStatuses.includes(filteredLift.StatusEnglish),
            );
          } else {
            return filteredLifts.filter(
              filteredLift => this.openStatuses.includes(filteredLift.StatusEnglish),
            );

          }
        }

      }

      // Use the API endpoint with this, remove .length from {{ totalOpenLifts }}
      // if (this.snow_report.TotalOpenLifts) {
      //   return this.snow_report.TotalOpenLifts;
      // }

      return 0;
    },
    totalLifts() {
      if (this.snow_report.TotalLifts) {
        return this.snow_report.TotalLifts;
      }
      return 0;
    },
    totalOpenTerrainParks() {
      if (this.snow_report.TotalOpenParks) {
        return this.snow_report.TotalOpenParks;
      }
      return '0';
    },
    totalGroomedTrails() {
      if (this.snow_report.GroomedTrails) {
        return this.snow_report.GroomedTrails;
      }
      return 0;
    },
    totalTerrainParks() {
      if (this.snow_report.TotalParks) {
        return this.snow_report.TotalParks;
      }
      return 0;
    },
    newsPresent() {
      return this.snow_report.Report !== "--";
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .trails-overview {
    display: block;
    padding-top: 10px;

    @media screen and (min-width: $tablet) {
      padding-top: 30px;
    }
  }

  .trails-details {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
  }

  .main-block {
    text-align: center;
    text-transform: uppercase;
    border: 0.5px solid $brand-blue;
    border-left-width: 0;
    margin: 0;
    width: 100%;
    padding: 10px;

    &:first-of-type {
      border-left-width: 0.5px;

    }

    .block-text {
      color: $brand-blue;
      font-size: 28px;
      line-height: 1.2;
      margin: 0;
      font-family: $base-font-bold;
      font-weight: $headline-font-weight;

      @media screen and (min-width: $tablet) {
        font-size: 50px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 62px;
      }
    }

    .block-title {
      color: $brand-blue;
      font-family: $base-font-bold;
      font-weight: $headline-font-weight;
      font-size: 10px;
      line-height: 1;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 16px;
      }

      a {
        display: block;
        color: $brand-blue;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        text-decoration: none;
        font-size: 10px;
        line-height: 1;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }
    }
  }
}

</style>
