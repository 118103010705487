<template>
  <div class="shuttle">
    <div class="shuttle-title-box" v-if="shuttle">
      <div class="shuttle-name">{{ shuttle.Name }}</div>
      <div class="shuttle-status"  :class="{
        'closed': hasClosedStatus,
        'open': hasOpenStatus,
        'on-hold': hasHoldStatus,
        'scheduled': hasScheduledStatus
      }"><span>{{ shuttle.Status }}</span></div>
      <div class="shuttle-hours" :class="shuttle.StatusEnglish">{{ liftHours }}</div>
      <div class="shuttle-message">{{ shuttle.Status }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Shuttle',
  props: ['shuttle', 'index'],
  components: {
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapState(['shuttles', 'waits', 'mountainAreas']),
    ...mapGetters(['closedStatuses', 'openStatuses', 'holdStatuses', 'scheduledStatuses', 'getShuttleDetails', 'getLiftWaits']),

    shuttleDetails() {
      if (this.shuttle) {
        const shuttleName = this.shuttle.Name;
        //console.log(shuttleName);
        return this.getShuttleDetails(shuttleName);
      }
    },
    // liftClass() {
    //   return this.liftDetails.StatusEnglish;
    // },
    hasClosedStatus() {
      return this.closedStatuses.indexOf(this.shuttle.StatusEnglish) >= 0;
    },
    hasOpenStatus() {
      return this.openStatuses.indexOf(this.shuttle.StatusEnglish) >= 0;
    },
    hasHoldStatus() {
      return this.holdStatuses.indexOf(this.shuttle.StatusEnglish) >= 0;
    },
    hasScheduledStatus() {
      return this.scheduledStatuses.indexOf(this.shuttle.StatusEnglish) >= 0;
    },
    liftWaitDetails() {
      if (this.lift) {
        const liftName = this.lift.Name;
        return this.getLiftWaits(liftName);
      }
    },
    liftHours() {
      if (typeof this.shuttle.Hours === "undefined") {
        return;
      }
      if (this.shuttle.Hours === null) {
        return;
      }
      if (this.shuttle.Hours === "--") {
        return;
      }

      const hours = this.shuttle.Hours[this.todaysHours];

      if (hours.Open) {
        return `${hours.Open.replace(" AM", "am").replace(" PM", "pm")} - ${hours.Close.replace(
          " AM",
          "am"
        ).replace(" PM", "pm")}`;
      }
      return "";
    },
    todaysHours() {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const d = new Date();
      return days[d.getDay()];
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .shuttle {
    background-color: $white;
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: stretch;

    .shuttle-title-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      min-height: 50px;
      border-top: 0.5px solid $brand-blue;
      flex: 1 100%;
    }

    .shuttle-name,
    .shuttle-status,
    .shuttle-wait,
    .shuttle-hours,
    .shuttle-message {
      color: $brand-gray;
      display: block;
      font-family: $base-font-regular;
      font-size: 12px;
      line-height: 1.2;
      padding: 10px;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
        letter-spacing: 0.2px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 16px;
      }
    }

    .shuttle-status {
      display: block;

      span {
        width: 16px;
        height: 16px;
        display: inline-block;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0;
        margin-right: 5px;
        line-height: 1;
        text-indent: -9999px;

        @media screen and (min-width: $tablet) {
          width: 18px;
          height: 18px;
        }
      }
    }

    .shuttle-name {
      flex: 1 auto;
      min-width: 150px;
    }

    .shuttle-status {
      text-align: center;
      flex: 0 80px;
      min-width: 80px;
    }

    .shuttle-wait,
    .shuttle-hours {
      text-align: center;
      flex: 0 120px;
      min-width: 120px;

      @media screen and (min-width: $tablet) {
        flex: 0 150px;
        min-width: 150px;
      }
      @media screen and (min-width: $tablet-landscape) {
        flex: 0 180px;
        min-width: 180px;
      }
    }

    .shuttle-message {
      text-align: left;
      flex: 0 200px;
      min-width: 200px;
    }
  }
}
</style>
