<template>
  <div v-if="checkActiveSection('weather')">
  </div>
  <div v-else-if="checkActiveSection('webcams')">
  </div>
  <div class="section-navigation" v-else-if="checkActiveSection('maps')">
      <ul class="top-list maps">
        <li>
          <a href="#"  @click.prevent="setActiveSection('lifts')">
          <span tabindex="-1">
            List
          </span>
          </a>
        </li>
        <li class="active">
          <a href="#" @click.prevent="setActiveSection('maps')">
          <span tabindex="-1">
            Map
          </span>
          </a>
        </li>
      </ul>
    </div>
  <div class="section-navigation" v-else>
      <ul class="top-list lifts">
        <li class="active">
          <a href="#"  @click.prevent="setActiveSection('lifts')">
          <span tabindex="-1">
            List
          </span>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="setActiveSection('maps')">
          <span tabindex="-1">
            Maps
          </span>
          </a>
        </li>
      </ul>

      <ul class="filter-list">
        <li v-for="section in allSections" :class="{ active: checkActiveSection(section) }">
          <a :href="'#/' + section" :name="section" :title="section" @click.prevent="setActiveSection(section)">
            <span tabindex="-1">
              {{ sectionNames[section] }}
            </span>
          </a>
        </li>
      </ul>

    <a class="toggle-legend" href="#" @click.prevent="toggleStatus()">
      Legend
      <span><span></span></span>
    </a>

      <div class="open-status-legend" v-if="legendVisible">
        <ul class="status-items">
          <li class="status-item open">
            <span>Open</span>
          </li>
          <li class="status-item closed">
            <span>Closed</span>
          </li>
          <li class="status-item scheduled">
            <span>Scheduled</span>
          </li>
          <li class="status-item on-hold">
            <span>On Hold</span>
          </li>
        </ul>
        <ul class="status-items" v-if="checkActiveSection('trails') || checkActiveSection('parks')">
          <li class="status-item groomed condition">
            <span>Groomed</span>
          </li>
          <li class="status-item snowmaking condition">
            <span>Snowmaking</span>
          </li>
        </ul>
        <ul class="status-items" v-if="checkActiveSection('trails')">
          <li class="status-item green trail-rating">
            <span>Beginner</span>
          </li>
          <li class="status-item blue trail-rating">
            <span>Intermediate</span>
          </li>
          <li class="status-item black trail-rating">
            <span>Advanced</span>
          </li>
          <li class="status-item double-black trail-rating">
            <span>Expert</span>
          </li>
        </ul>
        <ul class="status-items" v-if="checkActiveSection('parks')">
          <li class="status-item small park-rating">
            <span>Small</span>
          </li>
          <li class="status-item medium park-rating">
            <span>Medium</span>
          </li>
        </ul>
      </div>

<!--      <p class="disclaimer" v-if="showLegalDisclaimer">* {{ legalDisclaimer }}</p>-->
    </div>
</template>

<script>
import { mapState } from 'vuex';


export default {
  name: 'SectionNavigation',
  data() {
    return {
      activeSection: 'lifts',
      legendVisible: false
    };
  },
  components: { },
  created() {
    var path = this.$route.path;
    if( path !== '/') {
      this.setActiveSection('lifts');
      // scroll to anchor here?
      // setTimeout(function() {
      //   var section = document.querySelectorAll('.section-navigation')[0];
      //   window.scrollBy(section.offsetTop, section.offsetTop)
      // }, 1000);
    }
  },
  watch: {
    // '$route': function (to, from) {
    //   if( to.path == '/' || to.path == '/lifts' ) {
    //     this.setActiveSection('lifts');
    //   } else {
    //     this.setActiveSection(to.path.replace("/", ""));
    //   }
    // }
  },
  computed: {
    ...mapState(['allSections', 'attractionsText', 'legalDisclaimer', 'sectionNames']),
    // legalDisclaimerPresent() {
    //   return typeof(this.legalDisclaimer) !== 'undefined' && this.legalDisclaimer !== '--';
    // },
    // showLegalDisclaimer() {
    //   if( this.legalDisclaimerPresent !== true ) { return false; }
    //
    //   return this.activeSection == 'trails' || this.activeSection == 'lifts';
    // }
  },
  methods: {
    checkActiveSection(sectionName) {
      const routeName = this.$route.path.replace('/', '');
      return (this.activeSection == sectionName);
    },
    setActiveSection(section) {
      this.activeSection = section;
      // this.$router.push({ path: '/' + section })
      this.$store.commit('SET_ACTIVE_MOUNTAIN_SECTION', section);
    },
    toggleStatus() {
      this.legendVisible = !this.legendVisible;
    }
  },

};
</script>

<style lang="scss" scoped>
  #dashboard {
    .section-navigation {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items:flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      ul.top-list {
        padding: 0;
        flex: 0 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 30px 0 0;

        @media screen and (min-width: $tablet) {
          margin: 40px 0 0;
        }

        //&.lifts {
        //  margin: 30px 0;
        //
        //  @media screen and (min-width: $tablet) {
        //    margin: 30px 0;
        //  }
        //}

        li {
          flex: 1 50%;
          background: $white;
          border: .5px solid $brand-blue;
          border-left: 0;
          display: block;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
          vertical-align: baseline;

          @media screen and (min-width: $tablet) {
            letter-spacing: 0.85px;
          }

          &.active {
            display: block;
            background: $brand-blue;

            a {
              color: $white;
            }
          }
          a {
            display: block;
            color: $brand-blue;
            font-family: $base-font-bold;
            font-weight: $headline-font-weight;
            text-align: center;
            text-decoration: none;
            font-size: 11px;
            line-height: 1;
            padding: 12px 2px;

            @media screen and (min-width: $tablet) {
              font-size: 14px;
              padding: 14px 5px;
            }

            @media screen and (min-width: $tablet-landscape) {
              font-size: 14px;
              padding: 16px 5px;
            }

          }
          &:first-child {
            border: .5px solid $brand-blue;
          }
        }
      }


      ul.filter-list {
        flex: 0 0 100%;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
        margin: 30px 0 0;

        @media screen and (min-width: $tablet) {
          flex: 1 0 100%;
          margin: 40px 0 0;
        }
        @media screen and (min-width: $tablet-landscape) {
          flex: 0 0 880px;
        }

        li {
          flex: 0 1 50%;
          display: block;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
          vertical-align: baseline;
          background: $white;
          border: .5px solid $brand-blue;
          border-left: 0;

          @media screen and (min-width: $tablet) {
            letter-spacing: 0.85px;
            flex: 1 0 10%;
          }

          &.active {
            display: block;
            background: $brand-blue;
            a {
              color: $white;
            }
          }
          &:hover {
            background: $brand-blue;

            a {
              color: $white;
            }
          }
          a {
            display: block;
            color: $brand-blue;
            font-family: $base-font-bold;
            font-weight: $headline-font-weight;
            text-decoration: none;
            text-align: center;
            font-size: 12px;
            line-height: 1;
            padding: 12px 4px;

            @media screen and (min-width: $tablet) {
              font-size: 14px;
              padding: 14px 4px;
            }

            @media screen and (min-width: $tablet-landscape) {
              padding: 16px 5px;
            }
          }
          &:first-child {
            border: .5px solid $brand-blue;
          }
        }
      }
      .select-arrow-wrapper {
        display: inline-block;
        width: auto;
        .select-down-arrow {
          float: left;
          margin-top: 0;
          @media (min-width: $tablet) {
            display: none;
          }
          svg {
            width: 20px;
          }
        }
        select {
          display: block;
          height: 70%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0%;
          width: 70%;
          z-index: 10;
          @media screen and (min-width: $tablet) {
            display: none;
          }
        }
      }
      p.disclaimer {
        color: $medium-gray;
        font-family: $base-font-regular;
        font-size: 12px;
        line-height: 1.2;
        margin-bottom: 40px;
        @media screen and (min-width: $tablet) {
          font-size: 18px;
        }
      }
    }
    .toggle-legend{
      display:inline-block;
      text-align:center;
      margin:20px auto 0;
      color: $brand-blue;
      font-family: $base-font-bold;
      font-weight: $headline-font-weight;
      text-transform:uppercase;
      font-size: 12px;
      text-decoration:none;
      position:relative;
      padding-right:25px;
      line-height:1;

      > span{
        width:20px; height:20px; position:absolute; top:50%; right:0; transform:translateY(-50%);

        span{
          position:absolute; display:block; bottom: 10px; left: 10px; transform: translateX(-50%) translateY(50%);
          &:before{
            content: "";
            display: block;
            font-family: "Intrawest Icons";
            font-size: 60px;
            width:60px;
            height:60px;
          }
        }
      }

      @media screen and (min-width: $tablet) {
        font-size: 14px;
      }
      @media screen and (min-width: $tablet-landscape) {
        margin-right:0; margin-top:56px;
      }

    }
    .open-status-legend{
      margin-top:20px
    }
  }
</style>
