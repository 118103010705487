/* eslint-disable camelcase */

export default {
  resortName: state => state.resort.Name,
  resortSluggedName: (state) => {
    if (state.resort && state.resort.Name) {
      return state.resort.Name.toLowerCase().split(' ').join('-');
    }
  },
  currentActiveMountainArea: state => state.activeMountainArea,
  currentActiveWeatherZone: state => state.activeWeatherZone,
  activeMountainAreaTrails: (state) => {
    if (state.activeMountainArea) {
      return state.activeMountainArea.Trails;
    }
    return state.trails;
  },
  activeMountainAreaLifts: (state) => {
    if (state.activeMountainArea) {
      return state.activeMountainArea.Lifts;
    }
    return state.lifts;
  },
  getShuttles: state => state.mtnShuttles,
  getShuttleDetails: state => shuttleName => {
    if (state.mtnShuttles) {
      // console.log(shuttleName);
      return state.shuttles.find(shuttle => shuttle.Name === liftName);
    }
  },
  trailDifficultyMappings: (state) => {
    return {
      ...state.defaultTrailDifficultyMappings,
      ...state.trailDifficultyMappings
    };
  },
  getLiftDetails: state => (liftName) => {
    if (state.lifts) {
      // console.log(liftName);
      return state.lifts.find(lift => lift.Name === liftName);
    }
  },
  getLiftWaits: state => (liftName) => {
    if (state.waits) {
      // console.log(liftName);
      return state.waits.find(wait => wait.name === liftName);
    }
  },
  getAreaDetails: state => (trailName) => {
    if (state.mountainAreas) {
      // console.log(liftName);
      return state.mountainAreas.find(area => area.Name === areaName);
    }
  },
  getKidsLifts: state => state.kidsLifts,
  getParksGrouping: state => state.mtnParksGrouping,
  getTrailsSorting: state => state.mtnTrailsSorting,
  getTrailDetails: state => (trailName) => {
    if (state.trails) {
      // console.log(liftName);
      return state.trails.find(trail => trail.Name === trailName);
    }
  },
  getLiftsGrouping: state => state.mtnLiftsGrouping,
  getActiveMountainAreaLifts: (state) => {
    if (state.activeMountainArea === 'all') {
      return state.trails;
    }
    return state.activeMountainArea.Lifts;
  },
  activeMountainSection: state => state.activeMountainSection,
  getMaps: state => state.maps,
  baseUrl: state => state.base_url,
  filterIcons: state => state.filterIcons,
  mtnAreaToggles: state => state.mtnAreaToggles,
  customIcons: state => state.customIcons,
  allTrailsLength: state => (state.trails ? state.trails.length : []),
  allOpenTrails: state => (state.snow_report ? state.snow_report.TotalOpenTrails : []),
  allOpenNightTrailsLength: (state) => {
    if (state.trails) {
      return state.trails.filter(trail => trail.NightSkiing === 'Yes').length;
    }
    return 0;
  },
  waits: state => state.waits,
  webcams: state => state.webcams,
  weatherLayoutOptions: state => state.weatherLayoutOptions,
  weatherRoundValues: state => state.weatherRoundValues,
  openStatuses: state => state.openStatuses,
  closedStatuses: state => state.closedStatuses,
  holdStatuses: state => state.holdStatuses,
  scheduledStatuses: state => state.scheduledStatuses,
  formattedLastUpdate: (state) => {
    // Maybe use moment for this
    if (state.resort.LastUpdate) {
      const date = state.resort.LastUpdate;
      const values = date.split(/[^0-9]/);
      let year = parseInt(values[0], 10);
      const month = parseInt(values[1], 10) - 1; // Month is zero based, subtract 1
      const day = parseInt(values[2], 10);
      let hours = parseInt(values[3], 10);
      let minutes = parseInt(values[4], 10);
      let seconds = parseInt(values[5], 10);
      let formattedDate = new Date(year, month, day, hours, minutes, seconds);
      let amOrPm = formattedDate.getHours() > 12 ? 'PM' : 'AM'

      year = formattedDate.getFullYear();
      hours = formattedDate.getHours() > 12 ?
        formattedDate.getHours() - 12 :
        formattedDate.getHours();
      minutes = formattedDate.getMinutes();
      seconds = formattedDate.getSeconds();
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      const replacement = `${hours}:${minutes}:${seconds}`;
      const pattern = new RegExp(`0?${formattedDate.getHours()}:${minutes}:${seconds}`);

      formattedDate = formattedDate.toString().replace(/GMT.*/g, '');
      const dateSplit = formattedDate.split(year);
      return dateSplit[0] + dateSplit[1].replace(pattern, replacement) + amOrPm;
    }
  },
};
