<template>
  <div class="map" v-if="index === active">
    <div class="map-wrapper" v-if="showInteractive">
      <div class="titles">
        <h2 class="map-name">{{map.name}}</h2>
      </div>
      <div class="interactive">
        <iframe :src="map.url" ></iframe>
      </div>
    </div>
    <div class="map-wrapper" v-if="showPdf">
      <a :href="map.url" target="_blank" class="map-link" v-if="showPdf">
        <h2>{{map.name}} PDF</h2>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Map',
  props: ['map', 'active', 'index'],
  components: { },
  data() {
    return {
      expanded: false,
      isActive: true,
    };
  },
  computed: {
    showPdf() {
      return this.map.url.toLowerCase().indexOf('pdf') > 0 || this.map.isPDF == true;
    },
    showInteractive() {
      return !this.showPdf && this.map.showLinkText !== false;
    },
  },
  methods: {
    mapClick() {
      window.open(this.map.url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
  #dashboard {
    .map {
      margin: 40px 0 30px;
      border: .5px solid $brand-blue;

      .titles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }

      .map-name {
        color: $brand-blue;
        display: block;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 1.2;
        margin: 0;
        text-transform: uppercase;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }


      .interactive {
        position: relative;
        padding-bottom: 57%;
        padding-top: 35px;
        height: 0;
        overflow: visible;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
  }
</style>
