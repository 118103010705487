<template>
  <div class="mountain-area-navigation">
    <div class="flex-grid">
      <div class="col navigation-col" v-if="showMtnAreaNav">
<!--        <slick class="navigation" ref="slick" :options="slickOptions" v-if="mountainAreas">-->
<!--          <div>-->
<!--            <a href="#all-area-trails" :class="{ active: checkActiveArea('All') }" @click.prevent="setToAllAreas()">-->
<!--              <span tabindex="-1">All</span>-->
<!--            </a>-->
<!--          </div>-->
<!--          <div v-for="area in mountainAreas" :key="area.Name">-->
<!--            <a href="#" :title="area['Name']" :class="{ active: checkActiveArea(area['Name']) }" @click.prevent="setActiveMountainArea(area)">-->
<!--              <span tabindex="-1">{{area['Name']}}</span>-->
<!--            </a>-->
<!--          </div>-->
<!--        </slick>-->
      </div>

<!--      <MountainAreaToggles class="col toggles-col" v-if="showAreaToggles" />-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Slick from 'vue-slick';
import MountainAreaToggles from '@/components/MountainAreas/MountainAreaToggles.vue';

export default {
  name: 'Navigation',

  components: {
    MountainAreaToggles,
    // Slick,
  },

  data() {
    return {
      activeArea: null,
      // slickOptions: {
      //   slidesToShow: 4,
      //   slidesToScroll: 2,
      //   infinite: false,
      //   variableWidth: true,
      //   arrows: true,
      //   prevArrow: '<button class="slick-prev2 slick-arrow2" aria-label="Previous" type="button" style="" aria-disabled="false">Previous</button>',
      //   nextArrow: '<button class="slick-next2 slick-arrow2" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
      //   responsive: [
      //     {
      //       breakpoint: 768,
      //       settings: {
      //         slidesToShow: 2,
      //         slidesToScroll: 2,
      //       },
      //     }],
      // },
    };
  },

  computed: {
    showAreaToggles() {
      return this.activeMountainSection === 'lifts';
    },
    showMtnAreaNav() {
      return this.mtnAreaNavSections.indexOf(this.activeMountainSection) >= 0;
    },
    ...mapState(['mountainAreas', 'activeMountainSection', 'activeMountainAreaName', 'mtnAreaNavSections']),
  },

  methods: {
    checkActiveArea(name) {
      return this.activeArea === name;
    },
    setToAllAreas(area) {
      this.activeArea = 'All';
      this.$store.commit('SET_ACTIVE_MOUNTAIN_AREA', null);
    },
    setActiveMountainArea(area) {
      this.activeArea = area.Name;
      this.$store.commit('SET_ACTIVE_MOUNTAIN_AREA', area);
    },
  },

  mounted() {
    this.activeArea = this.activeMountainAreaName;
  },
};
</script>

<style lang="scss">
  /* Slider */
  .slick-slider {
      position: relative;

      display: block;
      box-sizing: border-box;

      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
      outline: none;
  }
  .slick-list.dragging {
      cursor: pointer;
      cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
         -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
           -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }

  .slick-track {
      position: relative;
      top: 0;
      left: 0;

      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
      display: table;

      content: '';
  }
  .slick-track:after {
      clear: both;
  }
  .slick-loading .slick-track {
      visibility: hidden;
  }

  .slick-slide {
      display: none;
      float: left;

      height: 100%;
      min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
      float: right;
  }
  .slick-slide img {
      display: block;
  }
  .slick-slide.slick-loading img {
      display: none;
  }
  .slick-slide.dragging img {
      pointer-events: none;
  }
  .slick-initialized .slick-slide {
      display: block;
  }
  .slick-loading .slick-slide {
      visibility: hidden;
  }
  .slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  #dashboard {
    .mountain-area-navigation {
      .flex-grid {
        justify-content: space-between;
      }
      .navigation-col {
        align-items: flex-start;
        flex: 0 1 auto;
        width: 100%;
        @media (min-width: $tablet) {
          width: 50%;
        }
        @media (min-width: $tablet-landscape) {
          width: 60%;
        }
      }
      .toggles-col {
        flex: 0 0 calc(100% + 80px);
        margin-bottom: -20px;
        margin-left: -60px;
        padding: 25px 60px 20px;
        @media (min-width: $tablet) {
          flex: 0 0 400px;
          margin-bottom: 0px;
          margin-left: 0px;
          padding: 0;
        }
        &.toggles-3 {
          @media (min-width: $tablet) {
            flex: 0 0 580px;
          }
        }
      }
      .navigation {
        position: relative;
        padding-left: 25px;
        .slick-arrow2 {
          background: url('~@/assets/icons/right-arrow-white.svg') no-repeat center center;
          background-color: $quicklinks-background;
          border: none;
          cursor: pointer;
          display: inline-block;
          height: auto;
          padding: 5px 10px;
          position: absolute;
          text-indent: -999em;
          top: 7.5%;
          transition: opacity 0.25s ease-in-out;

          &.slick-prev2 {
            left: -5px;
            transform: rotate(180deg);
          }
          &.slick-next2 {
            right: -25px;
          }
          &.slick-disabled {
            pointer-events: none;
            opacity: 0.0;
          }
        }
        .slick-list {
          display: block;
          margin: 20px 0;
          @media (min-width: $tablet) {
            margin: 0 0 20px;
          }
          .slick-slide {
            display: inline-block;
            letter-spacing: 0.85px;
            margin-bottom: 10px;
            margin-right: 30px;
            opacity: 0.75;
            &:last-child {
              margin-right: 0;
            }
            &.slick-active {
              opacity: 1;
            }
            a {
              color: $medium-gray;
              font-family: $base-font-regular;
              font-size: 16px;
              line-height: 1.2;
              opacity: 0.7;
              @media (min-width: $tablet) {
                font-size: 26px;
                letter-spacing: 0.8px;
              }
              &.active {
                font-family: $base-font-bold;
                font-weight: 600;
                opacity: 1;
                &:after {
                  background-color: $medium-gray;
                  content: '';
                  display: block;
                  height: 4px;
                  position: absolute;
                  left: 0%;
                  position: relative;
                  top: 5px;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
