<template>
  <div id="mtnDashboardApp" class="mtn-dashboard-container" :class="resortSluggedName">
    <MobileAlert :alert="snow_report.Alert" :alertBtn="snow_report.StormRadar" :alertBtnText="snow_report.StormRadarButtonText" />
    <router-view/>
  </div>
</template>

<script type="text/javascript" src="//use.typekit.net/nso1vmt.js"></script>
<script type="text/javascript">try{Typekit.load();}catch(e){}</script>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import MobileAlert from '@/components/MobileAlert.vue';
import MobileNavigation from '@/components/MobileNavigation.vue';
import { resortConfig } from '@/store/data/resort.config';

export default {
  components: {
    MobileNavigation,
    MobileAlert,
  },

  data() {
    return {
      resort: resortConfig,
    };
  },

  computed: {
    ...mapState(['snow_report']),
    ...mapGetters(['resortSluggedName']),
  },

  methods: mapActions([
    'getResortInfo',
    // 'getResortLifts',
    'getWebcams',
    'getWeather',
    // 'getWaits',
  ]),

  created() {
    document.title = 'Mountain Dashboard';

    // TODO: create a "bootstrap" action for these:
    this.getResortInfo();
    // this.getResortLifts();
    this.getWebcams();
    this.getWeather();
    // this.getWaits();
  },
};
</script>

<style lang="scss">
@import '~@/scss/site';

#mtnDashboardApp {
  background-color: $body-bg;
  overflow: hidden;
  padding: 0px;
  margin: 0 -20px;

  @media (min-width: $tablet) {
    padding: 0;
  }

  a {
    border: 0;
    color: initial;
    font-family: initial;
    margin: initial;
    padding: initial;
    font-size: initial;
    font-weight: initial;
    letter-spacing: initial;
    line-height: initial;
  }
}
</style>
