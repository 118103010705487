<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <div class="modal-default-button" @click="$emit('closeStormModal')">
                <span class="ico-close"></span>
              </div>
            </div>

            <div class="modal-body">
              <div id="storm-radar">
                <span v-html="embed"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>

export default {
  name: 'StormRadar',
  props: ['embed'],
  data() {
    return {
    };
  },
  methods: {
    loadMap() {
      const aeris = new AerisWeather('KoNIBE6bnqcQ4YTiaFTD5', 'RZxzy7aOyU29xckR8rcZCcevquPia9jgk6kJjEIe');

      aeris.views().then(views => {
        const stormRadar = document.getElementById('storm-radar');
        const map = new views.Map(stormRadar, {
          map: {
            autoplay: true,
            center: 'alta,ut',
            zoom: 7,
            layers: {
              data: 'flat,radar',
              overlays: 'admin'
            }
          }
        });
      });


      // This is a bit messy right now, but didn't want to remove yet
      // new AerisMaps.Visualizer("#storm-radar", {
      //   loc: "alta,ut",
      //   autoplay: true,
      //   map: {
      //     zoom: 5,
      //     size: {
      //       width: 500,
      //       height: 300
      //     },

      //   },
      //   animation: {
      //     from: -3 * 3600,
      //     duration: 5
      //   }
      // });
    }
  },
  created() {
    // let aerisScript = document.createElement('script')
    // aerisScript.setAttribute('src', 'https://cdn.aerisapi.com/sdk/js/latest/aerisweather.min.js')
    // aerisScript.onload = this.loadMap;
    // document.head.appendChild(aerisScript)

    // let aerisCSS = document.createElement('link')
    // aerisCSS.type = 'text/css';
    // aerisCSS.rel = 'stylesheet';
    // aerisCSS.href = 'https://cdn.aerisapi.com/sdk/js/latest/aerisweather.css';
    // document.head.appendChild(aerisCSS);
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) {
        this.$emit('closeStormModal');
      }
    });
  },
};
</script>

<style lang="scss">
  .mtn-dashboard-container {
    #storm-radar {
      height: 100%;
      min-height: 400px;
      width: 100%;
      span {
        > img, * {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
</style>
