// TODO: this could be broken into Vuex modules

import {
  resortSlug,
  resortConfig,
} from './data/resort.config';

export default {
  resortSlug,
  status: 'Loading...',
  resort: {},
  activeMountainArea: null,
  activeMountainAreaName: resortConfig.defaultActiveMountainArea,
  activeMountainSection: resortConfig.activeMountainSection || 'lifts',
  allSections: resortConfig.sections || ['lifts', 'trails', 'attractions', 'maps'],
  sectionNames: resortConfig.sectionNames || {
    lifts: 'Lifts',
    trails: 'Trails',
    kids: 'Kids',
    parks: 'Parks',
    shuttles: 'Shuttles',
    maps: 'Maps',
  },
  activeDashboardSection: 'Weather',
  attractionsText: resortConfig.attractionsText,
  defaultToMetric: resortConfig.defaultToMetric || false,
  filterIcons: resortConfig.filterIcons,
  mtnAreaToggles: resortConfig.mtnAreaToggles,
  customIcons: resortConfig.customIcons,
  forecast: {},
  mountainAreas: null,
  promoBanner: resortConfig.promoBanner,
  trails: null,
  defaultTrailDifficultyMappings: resortConfig.defaultTrailDifficultyMappings,
  trailDifficultyMappings: resortConfig.trailDifficultyMappings,
  weatherZoneNames: resortConfig.weatherZoneNames || {
    base: 'Village',
    mid: 'Mid-Mtn',
    summit: 'Summit'
  },
  legalDisclaimer: null,
  lifts: null,
  excludeLiftsFromCount: resortConfig.excludeLiftsFromCount,
  openStatuses: resortConfig.openStatuses,
  closedStatuses: resortConfig.closedStatuses,
  scheduledStatuses: resortConfig.scheduledStatuses,
  holdStatuses: resortConfig.holdStatuses,
  maps: resortConfig.maps,
  mtnAreaNavSections: resortConfig.mtnAreaNavSections,
  shuttles: null,
  mtnShuttles: resortConfig.shuttles,
  kidsLifts: resortConfig.kidsLifts,
  mtnParksGrouping: resortConfig.mtnParksGrouping,
  mtnLiftsGrouping: resortConfig.mtnLiftsGrouping,
  mtnTrailsSorting: resortConfig.mtnTrailsSorting,
  quickLinks: resortConfig.quickLinks,
  quickLinksTitle: resortConfig.quickLinksTitle,
  snow_report: {},
  temperatureUnit: 'imperial',
  windspeedUnit: 'imperial',
  snowbaseUnit: 'imperial',
  trailsOverviewTitle: resortConfig.trailsOverviewTitle,
  trailsOverviewBlocks: resortConfig.trailsOverviewBlocks,
  trailsOverviewNames: resortConfig.trailsOverviewNames,
  waits: null,
  weather: null,
  activeWeatherZone: null,
  weatherZones: {},
  weatherLayoutOptions: null,
  weatherRoundValues: resortConfig.weatherRoundValues || false,
  weatherZoneDropdownMobileOnly: resortConfig.weatherZoneDropdownMobileOnly || false,
  defaultWeatherZone: resortConfig.defaultWeatherZone || 'base',
  defaultWeatherZones: resortConfig.weatherZones || ['base', 'mid', 'summit'],
  webcams: [],
  defaultWebcam: null,
};
