<template>
  <div class="zoneSelector flex-grid" :class="{ 'show-tabbed-nav' : weatherZoneDropdownMobileOnly }">
    <div class="tabbed-nav-menu col" :class="dropdownClassList">
        <a href="#"
          v-for="(zone, index) in defaultWeatherZones"
          :class="{ 'active': activeZone == zone }"
          @click.prevent="toggleZone(zone)"
        >
          <span tabindex="-1">{{ weatherZoneNames[zone] }}</span>
          </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ZoneSelector',

  data() {
    return {
      expanded: false,
      activeZone: null,
    };
  },

  created() {
    this.activeZone = this.defaultWeatherZone;
  },

  computed: {
    ...mapState([
      'defaultWeatherZone',
      'defaultWeatherZones',
      'weatherZoneNames',
      'activeDashboardSection',
      'weatherZoneDropdownMobileOnly',
    ]),
    ...mapGetters(['formattedLastUpdate']),
    dropdownClassList() {
      let classes = '';
      if (this.expanded) { classes = 'expanded' }

      if (this.activeDashboardSection !== 'Weather') {
        classes += ' hide-tablet';
      } else if (this.weatherZoneDropdownMobileOnly == true ) {
        classes += ' show-tablet';
      }

      return classes;
    }
  },

  methods: {
    toggleZone(zoneLocation) {
      this.activeZone = zoneLocation;
      this.expanded = false;
      this.$store.commit('SET_ACTIVE_WEATHER_ZONE', zoneLocation);
    },
    expandClick() {
      if (this.defaultWeatherZones.length > 1) {
        this.expanded = !this.expanded;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .zoneSelector {

    .dropdown-menu {
      cursor: pointer;
      display: inline-block;
      margin-right: 0;
      padding-right: 0;
      position: relative;
      min-width: 200px;
      @media (min-width: $tablet) {
        min-width: 60%;
      }
      @media (min-width: $tablet-landscape) {
        min-width: 50%;
      }

      &.expanded {
        background-color: $dark-gray;
      }

      img.check {
        margin: 0 22px 0 10px !important;
        width: 11px;
        @media (min-width: $tablet) {
          margin: 0 22px 0 42px;
        }

        &.hidden-check {
          visibility: hidden;
        }
      }

      img.arrow {
        height: auto;
        margin: 0 10px 9px 20px !important;
        position: relative;
        top: 10px;
        width: 18px;
        @media screen and (min-width: $tablet) {
          top: 5px;
          width: 23px;
        }

        &.hidden-arrow {
          display: none;
        }
      }

      p {
        color: $dark-gray;
        font-family: $headline-font-regular;
        font-size: 19px;
        font-weight: 500;
        letter-spacing: 0.64px;
        height: 60px;
        line-height: 2.11;
        margin: 0;
        padding: 10px 0 0 0;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 2;
        transition: background-color 0.25s;

        @media screen and (min-width: $tablet) {
          font-size: 32px;
          line-height: 1.2;
          padding: 10px 0 0 25px;
        }

        &.expanded {
          background-color: $dark-gray;
          color: white;
          box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);
          min-width: 300px;
          max-width: auto;
          @media (min-width: $mobile) {
            min-width: 100%;
          }
        }
      }

      ul {
        background-color: $dark-gray;
        color: white;
        max-height: 0;
        list-style: none;
        margin: 10px 0 0 0;
        overflow: hidden;
        padding: 0 20px 0 10px;
        position: absolute;
        top: 50px;
        transition: max-height 0.35s;
        visibility: hidden;
        width: 100%;
        z-index: 1;

        &.expanded {
          visibility: visible;
          box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.23);
          max-height: 100vh;
          transition: max-height 0.5s 0.2s;
          min-width: 300px;
          @media (min-width: $mobile) {
            min-width: 100%;
          }
        }

        li {
          display: flex;
          align-items: center;
          font-size: 20px;
          height: 51px;

          &.line {
            border-top: solid 0.5px rgba(255, 255, 255, 0.18);
          }
        }
      }
    }

    .tabbed-nav-menu {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      margin-bottom: 30px;

      @media (min-width: 900px) {
        margin-bottom: 40px;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        vertical-align: baseline;
        border: .5px solid $brand-blue;
        background: $white;
        color: $brand-blue;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        letter-spacing: 1px;
        border-left: 0;
        text-align: center;
        text-decoration: none;
        font-size: 12px;
        line-height: 1;
        padding: 12px 6px;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
          padding: 14px 8px;
        }

        @media screen and (min-width: $tablet-landscape) {
          font-size: 14px;
          padding: 16px 10px;
        }

        &.active {
          background: $brand-blue;
          color: $white;
        }

        &:first-child {
          border: .5px solid $brand-blue;
        }
      }
    }

    .updatedTime {
      color: $dark-gray;
      font-family: $base-font-regular;
      float: right;
      font-size: 10px;
      line-height: 1.2;
      letter-spacing: 0.5px;
      margin-right: 10px;
      text-align: right;
      padding-top: 25px;

      label {
        display: none;
      }

      @media (min-width: $tablet) {
        font-size: 12px;
        padding-top: 30px;
        margin-right: 5px;
        max-width: 35%;
        label {
          display: inline;
        }
      }
    }
  }
}
</style>
