/* eslint-disable camelcase */
import axios from 'axios';
import { resortConfig } from './data/resort.config';

// TODO: this doesn't need to be dynamic since we only serve one mountain at a time
const BASE_URLS = {
  Local: '//amr.test:3000/feed',
  Staging: 'https://mtnpowder.com/feed', // '//snowreporting-staging.herokuapp.com/feed',
  Production: 'https://mtnpowder.com/feed',  // Production: '//mtnpowder.com/feed',
};
let baseUrl = null;
const getBaseURL = (path) => {
  if (!baseUrl) {
    const host = window.location.host.split(':')[0];
    baseUrl = (host.indexOf('staging') > -1) ? BASE_URLS.Staging : BASE_URLS.Production;
  }
  return baseUrl + path;
};

export default {
  getResortInfo({ commit, state }) {
    const resortUrl = getBaseURL(`?resortId=${resortConfig.resort_id}`);

    return axios.get(resortUrl)
      .then((response) => {
        commit('POPULATE_RESORT', response.data);
      })
      .catch((error) => {
        commit('API_FAILURE', error);
      });
  },
  // getResortLifts({ commit, state }) {
  //   const resortUrl = getBaseURL(`/${resortConfig.resort_id}/lifts`);
  //
  //   return axios.get(resortUrl)
  //     .then((response) => {
  //       commit('POPULATE_LIFTS', response.data);
  //     })
  //     .catch((error) => {
  //       commit('API_FAILURE', error);
  //     });
  // },
  getWeather({ commit, state }) {
    const resortUrl = getBaseURL(`/${resortConfig.resort_id}/weather`);

    return axios.get(resortUrl)
      .then((response) => {
        commit('POPULATE_WEATHER', response.data);
      })
      .catch((error) => {
        commit('API_FAILURE', error);
      });
  },
  getWebcams({ commit, state }) {
    const resortUrl = getBaseURL(`/${resortConfig.resort_id}/webcams`);

    return axios.get(resortUrl)
      .then((response) => {
        commit('POPULATE_WEBCAMS', response.data);
      })
      .catch((error) => {
        commit('API_FAILURE', error);
      });
  },
  // getWaits({ commit, state }) {
  //   const waitApiUrl = 'https://api.app.squawalpine.com/api/resortInfos/lift_wait_times_demo.json?api_key=e2e44398-8ae1-41df-bfee-19cffe5e2e22';
  //
  //   return axios.get(waitApiUrl)
  //     .then((response) => {
  //       commit('POPULATE_WAITS', response.data);
  //     })
  //     .catch((error) => {
  //       commit('API_FAILURE', error);
  //     });
  // },

  setWeatherUnits({ commit }, units) {
    for (const unit of Object.keys(units)) {
      commit(`SET_${unit.toUpperCase()}`, units[unit]);
    }
  },
};
