<template>
  <div class="mountain-area-toggles flex-grid" :class="`toggles-${numberOfTogglesClass}`">
    <div class="col toggle trails-toggle" v-if="showTrailsToggle">
      <span class="label" id="label-open-trails">Closed Trails</span>
      <input type="checkbox" aria-labelledby="label-open-trails" id="open" v-model="openChecked" class="cbx hidden"></input>
      <label for="open" class="toggle-switch" aria-label="Open Trails Toggle">
        <span>Open Trails Toggle</span>
      </label>
    </div>

    <div class="col toggle grooming-toggle" v-if="showGroomingToggle">
      <span class="label" id="label-grooming">Grooming</span>
      <input type="checkbox" aria-labelledby="label-grooming" id="grooming" v-model="groomingChecked" class="cbx hidden"></input>
      <label for="grooming" class="toggle-switch" aria-label="Grooming Toggle">
        <span>Groomming Toggle</span>
      </label>
    </div>

    <div class="col toggle legend-toggle" v-if="showLegendToggle">
      <span class="label" id="label-legend">Legend</span>
      <input type="checkbox" aria-labelledby="label-legend" id="legend-toggle" v-model="legendChecked" class="cbx hidden"></input>
      <label for="legend-toggle" class="toggle-switch" aria-label="Legend Toggle">
        <span>Legend Toggle</span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MountainAreaToggles',
  components: {
  },
  data() {
    return {
    };
  },
  destroyed() {
    this.legendChecked = false;
    this.groomingChecked = false;
  },
  computed: {
    ...mapGetters(['mtnAreaToggles', 'activeMountainSection']),
    openChecked: {
      get() {
        return this.mtnAreaToggles.closedTrailsValue;
      },
      set(newValue) {
        this.mtnAreaToggles.closedTrailsValue = newValue == 1;
        if (this.mtnAreaToggles.closedTrailsValue == true) {
          this.mtnAreaToggles.groomingValue = false;
        }
      },
    },
    legendChecked: {
      get() {
        return this.mtnAreaToggles.legendValue;
      },
      set(newValue) {
        this.mtnAreaToggles.legendValue = newValue == 1;
      },
    },
    groomingChecked: {
      get() {
        return this.mtnAreaToggles.groomingValue;
      },
      set(newValue) {
        this.mtnAreaToggles.groomingValue = newValue == 1;
        if (this.mtnAreaToggles.groomingValue == true) {
          this.mtnAreaToggles.closedTrailsValue = false;
        }
      },
    },
    numberOfTogglesClass() {
      return (this.showTrailsToggle ? 1 : 0) +
             (this.showGroomingToggle ? 1 : 0) +
             (this.showLegendToggle ? 1 : 0);
    },
    showTrailsToggle() {
      if (this.activeMountainSection !== 'trails') { return false; }

      return this.mtnAreaToggles.closedTrails;
    },
    showGroomingToggle() {
      if (this.activeMountainSection !== 'trails') { return false; }

      return this.mtnAreaToggles.grooming;
    },
    showLegendToggle() {
      if (this.activeMountainSection !== 'trails') { return false; }

      return this.mtnAreaToggles.legend;
    },
  },
  methods: {
    toggleOpenTrailsFilter() {
      this.open_trails = !this.open_trails;
    },
  },
};
</script>

<style lang="scss" scoped>
  #dashboard {
    .mountain-area-toggles {
      background-color: $background-medium-gray;
      .toggle {
        flex: 0 0 auto;
        white-space: nowrap;
        label.toggle-switch {
          span {
            position: absolute;
            left: -2000em;
          }
        }
        span.label {
          color: $dark-gray;
          font-family: $base-font-bold;
          font-size: 16px;
          line-height: 1.2;
          margin-right: 10px;
          position: relative;
          white-space: nowrap;
          top: -5px;
          @media (max-width: $small-phone) {
            font-size: 14px;
          }
          @media (min-width: $tablet) {
            font-size: 20px;
          }
        }
        &.legend-toggle {
          display: none;
          @media (min-width: $tablet) {
            display: block;
          }
        }
      }
      @media (min-width: $tablet) {
        background-color: transparent;
      }
    }
  }
</style>
