<template>
  <div class="mountain-area-legend" v-if="showLegend">
    <div class="category category--difficulty" v-if="showCategoryDifficulty">
      <div class="title">Difficulty</div>
      <div class="item item--easy" v-if="showEasy">
        <img :src="require('@/assets/icons/ratings/easy.svg')" alt="Easy Icon" class="icon icon--easy" />
        <div class="label">{{ trailDifficultyMappings['Easy'] }}</div>
      </div>
      <div class="item item--easy" v-if="showEasier">
        <img :src="require('@/assets/icons/ratings/easy.svg')" alt="Easier Icon" class="icon icon--easy" />
        <div class="label">{{ trailDifficultyMappings['Easier'] }}</div>
      </div>
      <div class="item item--easy" v-if="showEasiest">
        <img :src="require('@/assets/icons/ratings/easy.svg')" alt="Easiest Icon" class="icon icon--easy" />
        <div class="label">{{ trailDifficultyMappings['Easiest'] }}</div>
      </div>
      <div class="item item--intermediate" v-if="showIntermediate">
        <img :src="require('@/assets/icons/ratings/intermediate.svg')" alt="Intermediate Icon" class="icon icon--intermediate" />
        <div class="label">{{ trailDifficultyMappings['Intermediate'] }}</div>
      </div>
      <div class="item item--moderately-difficult" v-if="showModeratelyDifficult">
        <img :src="require('@/assets/icons/ratings/intermediate.svg')" alt="Moderately Difficult Icon" class="icon icon--intermediate" />
        <div class="label">{{ trailDifficultyMappings['Moderately Difficult'] }}</div>
      </div>
      <div class="item item--advanced-intermediate" v-if="showAdvancedIntermediate">
        <img :src="require(`@/assets/icons/${advancedIntermediateIcon}.svg`)" alt="Advanced Intermediate Icon" class="icon icon--advanced-intermediate" />
        <div class="label">{{ trailDifficultyMappings['Advanced Intermediate'] }}</div>
      </div>
      <div class="item item--advanced" v-if="showDifficult">
        <img :src="require('@/assets/icons/ratings/advanced.svg')" alt="Difficult Icon" class="icon icon--advanced" />
        <div class="label">{{ trailDifficultyMappings['Difficult'] }}</div>
      </div>
      <div class="item item--very-difficult" v-if="showVeryDifficult">
        <img :src="require('@/assets/icons/ratings/advanced.svg')" alt="Very Difficult Icon" class="icon icon--advanced" />
        <div class="label">{{ trailDifficultyMappings['Very difficult'] }}</div>
      </div>
      <div class="item item--expert" v-if="showExpert">
        <img :src="require(`@/assets/icons/${expertIcon}.svg`)" alt="Expert Icon" class="icon icon--expert" />
        <div class="label">{{ trailDifficultyMappings['Expert'] }}</div>
      </div>
      <div class="item item--expert" v-if="showExtremeTerrain">
        <img :src="require('@/assets/icons/ratings/extreme-terrain.svg')" alt="Extreme Terrain Icon" class="icon icon--extreme-terrain" />
        <div class="label">{{ trailDifficultyMappings['Extreme Terrain'] }}</div>
      </div>
      <div class="item item--terrainpark" v-if="showParkSmall">
        <img :src="require('@/assets/icons/ratings/terrain_park_small.svg')" alt="Terrain Park Small Icon" class="icon icon--terrainpark" />
        <div class="label">{{ trailDifficultyMappings['SmallPark'] }}</div>
      </div>
      <div class="item item--terrainpark" v-if="showParkMedium">
        <img :src="require('@/assets/icons/ratings/terrain_park_medium.svg')" alt="Terrain Park Medium Icon" class="icon icon--terrainpark" />
        <div class="label">{{ trailDifficultyMappings['MediumPark'] }}</div>
      </div>
      <div class="item item--terrainpark" v-if="showParkLarge">
        <img :src="require('@/assets/icons/ratings/terrain_park_large.svg')" alt="Terrain Park Large Icon" class="icon icon--terrainpark" />
        <div class="label">{{ trailDifficultyMappings['LargePark'] }}</div>
      </div>
      <div class="item item--terrainpark" v-if="showParkExtreme">
        <img :src="require('@/assets/icons/ratings/terrain_park_extreme.svg')" alt="Terrain Park Extreme Icon" class="icon icon--terrainpark" />
        <div class="label">Terrain Park Extreme</div>
      </div>
      <div class="item item--adventurezone" v-if="showAdventureZone">
        <img :src="require('@/assets/icons/ratings/purple_star.svg')" alt="Adventure Zone Icon" class="icon icon--adventurezone" />
        <div class="label">Adventure Zone</div>
      </div>
    </div>

    <div class="category category--grooming" v-if="showCategoryGrooming">
      <div class="title">Grooming</div>
      <div class="item item--grooming" v-if="showGrooming">
        <img :src="require('@/assets/icons/grooming.svg')" alt="Grooming Icon" class="icon icon--grooming" />
        <div class="label">Grooming</div>
      </div>
      <div class="item item--groomingtop" v-if="showGroomingTop">
        <img :src="require('@/assets/icons/grooming_top.svg')" alt="Grooming Top Icon" class="icon icon--groomingtop" />
        <div class="label">Grooming Top</div>
      </div>
      <div class="item item--groomingbottom" v-if="showGroomingBottom">
        <img :src="require('@/assets/icons/grooming_bottom.svg')" alt="Grooming Bottom Icon" class="icon icon--groomingbottom" />
        <div class="label">Grooming Bottom</div>
      </div>
      <div class="item item--groomingfirstshift" v-if="showGroomingFirstShift">
        <img :src="require('@/assets/icons/grooming_first_shift.svg')" alt="Grooming First Shift Icon" class="icon icon--groomingfirstshift" />
        <div class="label">Grooming First Shift</div>
      </div>
      <div class="item item--groomingsecondshift" v-if="showGroomingSecondShift">
        <img :src="require('@/assets/icons/grooming_second_shift.svg')" alt="Grooming Second Shift Icon" class="icon icon--groomingsecondshift" />
        <div class="label">Grooming Second Shift</div>
      </div>
      <div class="item item--snowmaking" v-if="showSnowmaking">
        <img :src="require('@/assets/icons/snowmaking.svg')" alt="Snowmaking Icon" class="icon icon--snowmaking" />
        <div class="label">Snowmaking</div>
      </div>
      <div class="item item--nightsnowmaking" v-if="showNightSnowmaking">
        <img :src="require('@/assets/icons/snowmaking_overnight.svg')" alt="Night Snowmaking Icon" class="icon icon--nightsnowmaking" />
        <div class="label">Night Snowmaking</div>
      </div>
    </div>

    <div class="category category--features" v-if="showCategoryFeatures">
      <div class="title">Features</div>
      <div class="item item--moguls" v-if="showMoguls">
        <img :src="require('@/assets/icons/moguls.svg')" alt="Moguls Icon" class="icon icon--moguls" />
        <div class="label">{{ trailDifficultyMappings['Moguls'] }}</div>
      </div>
      <div class="item item--mogulstop" v-if="showMogulsTop">
        <img :src="require('@/assets/icons/moguls_top.svg')" alt="Moguls Top Icon" class="icon icon--mogulstop" />
        <div class="label">{{ trailDifficultyMappings['Moguls Top'] }}</div>
      </div>
      <div class="item item--mogulsbottom" v-if="showMogulsBottom">
        <img :src="require('@/assets/icons/moguls_bottom.svg')" alt="Moguls Bottom Icon" class="icon icon--mogulsbottom" />
        <div class="label">{{ trailDifficultyMappings['Moguls Bottom'] }}</div>
      </div>
      <div class="item item--glades" v-if="showGlades">
        <img :src="require('@/assets/icons/glades.svg')" alt="Glades Icon" class="icon icon--glades" />
        <div class="label">Glades</div>
      </div>
      <div class="item item--snowshoeing" v-if="showSnowshoeing">
        <img :src="require('@/assets/icons/snowshoeing.svg')" alt="Snowshoeing Icon" class="icon icon--snowshoeing" />
        <div class="label">Snowshoeing</div>
      </div>
      <div class="item item--touring" v-if="showTouring">
        <img :src="require(`@/assets/icons/${touringIcon}.svg`)" alt="Touring Icon" class="icon icon--touring" />
        <div class="label">{{ trailDifficultyMappings['Touring'] }}</div>
      </div>

      <div class="item item--nordic" v-if="showNordic">
        <img :src="require('@/assets/icons/nordic.svg')" alt="Nordic Icon" class="icon icon--nordic" />
        <div class="label">{{ trailDifficultyMappings['Nordic'] }}</div>
      </div>
    </div>

    <div class="category category--status" v-if="showCategoryStatus">
      <div class="title">Status</div>
      <div class="item item--race" v-if="showRace">
        <img :src="require('@/assets/icons/race_in_progress.svg')" alt="Race Icon" class="icon icon--race" />
        <div class="label">Race In Progress</div>
      </div>
      <div class="item item--nightskiing" v-if="showNightSkiing">
        <img :src="require('@/assets/icons/nightskiing.svg')" alt="Night Skiing Icon" class="icon icon--nightskiing" />
        <div class="label">Night Skiing</div>
      </div>
      <div class="item item--groomers-choice" v-if="showGroomersChoice">
        <img :src="require('@/assets/icons/groomers_choice.svg')" alt="Groomer's Choice Icon" class="icon icon--groomers-choice" />
        <div class="label">Groomer's Choice</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Legend',
  computed: {
    ...mapGetters(['activeMountainAreaTrails', 'mtnAreaToggles', 'customIcons', 'resortSluggedName', 'trailDifficultyMappings']),
    showCategoryDifficulty() {
      return true; // always show Difficulty category
    },
    showLegend() {
      return this.activeMountainAreaTrails && this.mtnAreaToggles.legendValue;
    },
    showCategoryGrooming() {
      return this.showGrooming
        || this.showGroomingTop
        || this.showGroomingBottom
        || this.showGroomingFirstShift
        || this.showGroomingSecondShift
        || this.showSnowmaking
        || this.showNightSnowmaking;
    },
    showCategoryFeatures() {
      return this.showMoguls
        || this.showMogulsTop
        || this.showMogulsBottom
        || this.showGlades
        || this.showSnowshoeing
        || this.showTouring
        || this.showNordic;
    },
    showCategoryStatus() {
      return this.showRace
        || this.showNightSkiing
        || this.showGroomersChoice;
    },
    showEasy() {
      return this.checkTrailsDifficulty('Easy');
    },
    showEasier() {
      return this.checkTrailsDifficulty('Easier');
    },
    showEasiest() {
      return this.checkTrailsDifficulty('Easiest');
    },
    showIntermediate() {
      return this.checkTrailsDifficulty('Intermediate');
    },
    showModeratelyDifficult() {
      return this.checkTrailsDifficulty('Moderately Difficult');
    },
    showAdvancedIntermediate() {
      return this.checkTrailsDifficulty('Advanced Intermediate');
    },
    showDifficult() {
      return this.checkTrailsDifficulty('Difficult');
    },
    showVeryDifficult() {
      return this.checkTrailsDifficulty('Very difficult');
    },
    showExpert() {
      return this.checkTrailsDifficulty('Expert');
    },
    showExtremeTerrain() {
      return this.checkTrailsDifficulty('Extreme Terrain');
    },
    showParkSmall() {
      return this.checkTrailsDifficulty('SmallPark');
    },
    showParkMedium() {
      return this.checkTrailsDifficulty('MediumPark');
    },
    showParkLarge() {
      return this.checkTrailsDifficulty('LargePark');
    },
    showParkExtreme() {
      return false;
    },
    showAdventureZone() {
      return this.checkTrailsDifficulty('Adventure Zone');
    },
    showGrooming() {
      return this.checkTrailsFor('Grooming', 'Yes');
    },
    showGroomingTop() {
      return this.checkTrailsFor('Grooming', 'Top');
    },
    showGroomingBottom() {
      return this.checkTrailsFor('Grooming', 'Bottom');
    },
    showGroomingFirstShift() {
      return this.checkTrailsFor('Grooming', 'First Shift');
    },
    showGroomingSecondShift() {
      return this.checkTrailsFor('Grooming', 'Second Shift');
    },
    showSnowmaking() {
      return this.checkTrailsFor('SnowMaking', 'Yes');
    },
    showNightSnowmaking() {
      return this.checkTrailsFor('SnowMaking', 'Overnight');
    },
    showMoguls() {
      return this.checkTrailsFor('Moguls', 'Yes');
    },
    showMogulsTop() {
      return this.checkTrailsFor('Moguls', 'Top');
    },
    showMogulsBottom() {
      return this.checkTrailsFor('Moguls', 'Bottom');
    },
    showGlades() {
      return this.checkTrailsFor('Glades', 'Yes');
    },
    showSnowshoeing() {
      return this.checkTrailsDifficulty('Snowshoe');
    },
    showTouring() {
      return this.checkTrailsFor('Touring', 'Yes');
    },
    touringIcon() {
      if( this.customIcons.indexOf('touring') >= 0 ) {
        return `resorts/${this.resortSluggedName}/touring`;
      }

      return `touring`;
    },
    advancedIntermediateIcon() {
      if( this.customIcons.indexOf('advancedIntermediate') >= 0 ) {
        return `resorts/${this.resortSluggedName}/ratings/advanced_intermediate`;
      }

      return `ratings/advanced_intermediate`;
    },
    expertIcon() {
      if( this.customIcons.indexOf('expert') >= 0 ) {
        return `resorts/${this.resortSluggedName}/ratings/expert`;
      }

      return `ratings/expert`;
    },
    showNordic() {
      return this.checkTrailsFor('Nordic', 'Yes');
    },
    showRace() {
      return this.checkTrailsFor('StatusEnglish', 'closed_race_in_progress');
    },
    showNightSkiing() {
      return this.checkTrailsFor('NightSkiing', 'Yes');
    },
    showGroomersChoice() {
      return this.checkTrailsFor('StatusEnglish', 'groomers_choice');
    },
  },

  methods: {
    checkTrailsDifficulty(difficulty) {
      const trails = this.activeMountainAreaTrails;
      let hasDifficulty = false;

      for (let i = 0; i < trails.length; i++) {
        const trail = trails[i];
        const trailDifficulty = this.lookupDifficulty(trail.Difficulty);
        if (trailDifficulty === difficulty) {
          hasDifficulty = true;
          break;
        }
      }

      return hasDifficulty;
    },
    lookupDifficulty(difficulty) {
      // The feed can have things like 'easier' for 'easy'
      if (difficulty == 'Easier') { return 'Easy'; }

      return difficulty;
    },
    checkTrailsFor(key, value) {
      const trails = this.activeMountainAreaTrails;
      let hasKey = false;

      for (let i = 0; i < trails.length; i++) {
        const trail = trails[i];
        if (trail[key] === value) {
          hasKey = true;
          break;
        }
      }

      return hasKey;
    },
  },
};
</script>

<style lang="scss" scoped>
  #dashboard {
    .mountain-area-legend {
      display: block;
      padding: 20px 35px;
      background-color: $white;

      .category {
        border-bottom: .5px solid rgba(255, 255, 255, 0.2);
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child {
          border-bottom: none;
        }

        .title {
          color: $brand-blue;
          font-family: $base-font-semibold;
          font-size: 18px;
          font-weight: bold;
          flex: 0 0 auto;
          margin-top: 1.5%;
          text-transform: uppercase;
          width: 15%;
        }

        &.category--difficulty {
          .item {
            .icon {
              &.icon--adventurezone {
              }
            }
          }
        }
        &.category--grooming {
          .item {
            .icon {
            }
          }
        }
        &.category--features {
          .item {
            .icon {
            }
          }
        }
        &.category--status {
        }

        .item {
          flex: 0 1 160px;
          margin: 0 2.5% 0 0;
          text-align: center;
          @media (min-width: $tablet-landscape) {
            margin: 0 4% 0 0;
          }

          .icon {
            display: block;
            height: auto;
            margin: 0 auto;
            max-width: initial;
            position: relative;
            width: 85px;
          }
          .label {
            color: $brand-blue;
            font-family: $base-font-regular;
            font-size: 18px;
            line-height: 1.2;
          }
        }
      }
    }
  }
</style>
