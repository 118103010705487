<template>
  <div class="mountain-section parks">
    <div class="mountain-area" v-for="(area, index) in groupingAreas">
      <div class="titles">
        <h1 class="ttu">{{ area.Name }}</h1>
        <span class="swipe"><img :src="require(`@/assets/icons/arrow-right-arrow-left.svg`)" alt="Swipe for more" class="icon" height="14px" />SCROLL</span>
      </div>
      <div class="scroll-wrapper">
        <div class="park-heading">
          <div class="park-name">Park Name</div>
          <div class="park-status">Status</div>
          <div class="park-icons">Condition</div>
          <div class="park-difficulty">Rating</div>
          <div class="park-message">Message</div>
        </div>

        <Park
          v-for="(park, index) in area.Trails"
          :index="index"
          :park="park"
          :key="park.Name">
        </Park>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Park from '@/components/MountainAreas/Park.vue';

export default {
  name: 'Parks',
  components: {
    Park,
  },
  data() {
    return {};
  },
  computed: {
    // trailMountainAreas() {
    //   if( this.mtnAreaNavSections.indexOf('trails') < 0 ) {
    //     return this.mountainAreas;
    //   }
    //
    //   return this.currentActiveMountainArea
    //     ? [this.currentActiveMountainArea]
    //     : this.mountainAreas;
    // },
    groupingAreas() {
      return this.mtnParksGrouping.area;
    },
    ...mapState(['trails', 'mountainAreas', 'mtnAreaNavSections', 'mtnParksGrouping']),
    ...mapGetters(['openStatuses', 'currentActiveMountainArea', 'openStatuses', 'getParksGrouping']),
  },
  methods: {
    // openTrails(parks) {
    //   return trails.filter(park => park.ExcludeInTotal !== 'true' && this.openStatuses.indexOf(park.StatusEnglish) >= 0).length;
    // },
    // totalTrails(parks) {
    //   return trails.filter(park => park.ExcludeInTotal !== 'true').length;
    // },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .mountain-area {
    border: .5px solid $brand-blue;
    margin-bottom: 30px;

    .scroll-wrapper {
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;

      @media screen and (min-width: $tablet) {
        overflow-x: hidden;
        overflow-y: hidden;
      }
    }

    .titles {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .swipe {
        color: $brand-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $base-font-bold;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 1.2;
        margin: 0;

        @media screen and (min-width: $tablet) {
          display: none;
        }

        img {
          margin-right: 5px;
        }
      }
    }
    h1 {
      color: $brand-blue;
      display: block;
      font-family: $base-font-bold;
      font-weight: $headline-font-weight;
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 1.2;
      margin: 0;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 16px;
      }
    }
    .park-counts {
      color: $brand-blue;
      display: inline-block;
      font-family: $base-font-regular;
      font-size: 16px;
      line-height: 1.2;
      strong {
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
      }
    }
  }
}
.park-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .park-name,
  .park-status,
  .park-icons,
  .park-difficulty,
  .park-message {
    color: $brand-blue;
    display: block;
    font-family: $base-font-bold;
    font-weight: $headline-font-weight;
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    padding: 10px;

    @media screen and (min-width: $tablet) {
      font-size: 14px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 16px;
    }
  }

  .park-name {
    flex: 1 auto;
    min-width: 150px;
  }

  .park-status,
  .park-icons,
  .park-difficulty {
    text-align: center;
    flex: 0 100px;
    min-width: 100px;
  }
  .park-icons{
    flex: 0 130px;
    min-width: 130px;
  }

  .park-message {
    text-align: left;
    flex: 0 200px;
    min-width: 200px;
  }
}
</style>
