<template>
  <div class="col data-block news-block rich-text" v-if="newsPresent">
    <div class="wrapper" :class="newsBarClasses">
      <div title="View News">
        <div class="heading-three">Conditions Blog</div>
        <p>
          <strong v-html="blogs[0].title.rendered"></strong><br />
          {{ excerpt }}<br />
          <a v-bind:href="blogs[0].link">Continue reading</a>
        </p>
        <!--
        <span v-html="shortText"></span>
        <span v-html="expandedText" v-if="expanded"></span>
        <a href="#" class="continue-reading" v-if="!expanded && expandedText" @click.prevent="toggleExpanded">
          <span>Continue reading</span>
        </a>
        <a href="#" class="continue-reading" v-if="expanded" @click.prevent="toggleExpanded">
          <span tabindex="-1">Collapse</span>
        </a>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'News',
  props: ['news'],
  data() {
    return {
      expanded: false,
      blogs: []
    };
  },
  created() {
    axios.get('https://blog.palisadestahoe.com/wp-json/wp/v2/posts?_fields=id,excerpt,title,link&per_page=1&categories=108')
        .then(response => {
          this.blogs = response.data;
        });
  },
  mounted() {
    if (this.$route.path.length > 0 && /expand-news/.test(this.$route.path)) {
      this.expanded = true;
    }
  },
  computed: {
    excerpt($value) {
      let regex = /(<([^>]+)>)/ig;
      return this.blogs[0].excerpt.rendered.replace(regex, "");
    },
    newsPresent() {
      // There is a feed update that changed null to ---
      return typeof (this.news) !== 'undefined' && this.news !== '--';
    },
    shortText() {
      if (this.news.indexOf('...more...') < 0) { return this.news; }

      return this.news.split('...more...')[0];
    },
    expandedText() {
      return this.news.split('...more...')[1];
    },
    newsBarClasses() {
      if (this.expanded) { return 'news-expanded'; }
    },
  },
  methods: {
    toggleExpanded() {
      if (this.expandedText) {
        this.expanded = !this.expanded;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.news-block {
  /*flex-basis: 100% !important;
  overflow: hidden;
  width: 100%;
  @media (max-width: $tablet-landscape) {
    margin-top: 0;
    min-width: 50%;
  }
  @media (min-width: $tablet-landscape) {
    flex-basis: 50% !important;
  }*/text-align: center;
  .wrapper {
    padding: 40px 15px 0;
    .heading-three{
      display: block; margin-bottom: 1em;
    }
    p{
      font-size: 0.9rem;
    }
  }
  /*a.continue-reading {
    color: $dark-gray;
    display: block;
    font-family: $base-font-bold;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 5px;
  }*/
}
</style>
