import RESORTS_CONFIG from '@/resorts.json';

export const resortSlug = process.env.VUE_APP_RESORT;
export const defaultConfig = RESORTS_CONFIG.default;

export const resortConfig = {
  ...defaultConfig,
  ...RESORTS_CONFIG[resortSlug],
};

export default { resortSlug, resortConfig };
