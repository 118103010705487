<template>
  <div class="weather">
    <div class="toggle-wrappers">
      <ZoneSelector />
      <UnitToggles />
    </div>
    <div class="forecast-wrapper weather">
      <div class="titles">
        <h2 class="title">Current Conditions</h2>
        <span class="swipe"><img :src="require(`@/assets/icons/arrow-right-arrow-left.svg`)" alt="Swipe for more" class="icon" height="14px" />SCROLL</span>
      </div>
      <div class="current-weather-block">
        <Current class="current"
                 :weatherZone="activeWeatherZone"
                 v-if="activeWeatherZone" />
      </div>
    </div>
    <div class="forecast-wrapper weather" v-if="isNotSummit">
      <div class="titles">
        <h2 class="title">Snowfall Report</h2>
        <span class="swipe"><img :src="require(`@/assets/icons/arrow-right-arrow-left.svg`)" alt="Swipe for more" class="icon" height="14px" />SCROLL</span>
      </div>
      <div class="current-weather-block">
        <Conditions class="current"
                 :currentArea="currentArea"
                 v-if="activeWeatherZone" />
      </div>
<!--      <div class="snow-report-block">-->
<!--        <div class="snowbase">-->
<!--          <h4>-->
<!--            Snow Base-->
<!--          </h4>-->
<!--          <div class="totals">-->
<!--            <div class="depth">-->
<!--              {{ snowBase }}-->
<!--              <div class="metric">-->
<!--                <span class="unit" v-if="snowbaseUnit === 'metric'"> cm</span>-->
<!--                <span class="unit" v-else> in</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="details">-->
<!--            <div class="detail">-->
<!--              Season Total: {{ snowSeasonTotal }}-->
<!--              <span class="unit" v-if="snowbaseUnit === 'metric'"> cm</span>-->
<!--              <span class="unit" v-else>"</span>-->
<!--            </div>-->
<!--            <div class="detail">-->
<!--              24 Hour: {{ snow24Hours }}-->
<!--              <span class="unit" v-if="snowbaseUnit === 'metric'"> cm</span>-->
<!--              <span class="unit" v-else>"</span>-->
<!--            </div>-->
<!--            <div class="detail">-->
<!--              48 Hour: {{ snow48Hours }}-->
<!--              <span class="unit" v-if="snowbaseUnit === 'metric'"> cm</span>-->
<!--              <span class="unit" v-else>"</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="snow-report-block storms">-->
<!--        <div class="stormbase">-->
<!--          <h4>-->
<!--            Storm Totals-->
<!--          </h4>-->
<!--          <div class="totals">-->
<!--            <span class="weather-icon ico-snow"></span>-->
<!--            <div class="depth">-->
<!--              {{ stormTotal }}-->
<!--              <div class="metric">-->
<!--                <span class="unit" v-if="snowbaseUnit === 'metric'"> cm</span>-->
<!--                <span class="unit" v-else> in</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="details">-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="forecast-wrapper weather">
      <div class="titles">
        <h2 class="title">Weather Forecast (8,200ft)</h2>
        <span class="swipe"><img :src="require(`@/assets/icons/arrow-right-arrow-left.svg`)" alt="Swipe for more" class="icon" height="14px" />SCROLL</span>
      </div>
      <div class="current-weather-block">
        <Forecast :forecastDays="forecastDays" />
      </div>
    </div>
    <div class="forecast-wrapper snow">
      <div class="titles">
        <h2 class="title">Snow Forecast (8,200ft)</h2>
        <span class="swipe"><img :src="require(`@/assets/icons/arrow-right-arrow-left.svg`)" alt="Swipe for more" class="icon" height="14px" />SCROLL</span>
      </div>
<!--      <div class="snow-depth-block" v-if="activeWeatherZone">-->
<!--        <Snowfall :forecastDays="forecastDays" />-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ZoneSelector from '@/components/ZoneSelector.vue';
import UnitToggles from './Weather/UnitToggles.vue';
import Current from './Weather/Current.vue';
import Forecast from './Weather/Forecast.vue';
// import Snowfall from './Weather/Snowfall.vue';
import Conditions from './Weather/Conditions.vue';
// import StormRadar from './Weather/StormRadar.vue';

export default {
  name: 'Weather',
  data() {
    return {
      showStormRadar: false
    }
  },
  components: {
    Current,
    ZoneSelector,
    Forecast,
    // Snowfall,
    Conditions,
    // StormRadar,
    UnitToggles,
  },
  computed: {
    base() {
      return this.snow_report.BaseArea;
    },
    mid() {
      return this.snow_report.MidMountainArea;
    },
    summit() {
      return this.snow_report.SummitArea;
    },

    isNotSummit() {
      if (this.activeWeatherZone) {
        return this.activeWeatherZone.Location !== 'summit';
      }
    },
    currentLocation() {
      if (this.activeWeatherZone) {
        return this.activeWeatherZone.Location;
      }
    },
    currentArea() {
      if (!this.activeWeatherZone) { return; }

      return this[this.currentLocation];
    },
    snowBase() {
      if (!this.activeWeatherZone) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.BaseCm.toString().split('.')[0]
          : this.currentArea.BaseIn.toString().split('.')[0];
      }
    },
    snow24Hours() {
      if (!this.activeWeatherZone) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.Last24HoursCm.toString().split('.')[0]
          : this.currentArea.Last24HoursIn.toString().split('.')[0];
      }
    },
    snow48Hours() {
      if (!this.activeWeatherZone) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.currentArea.Last48HoursCm.toString().split('.')[0]
          : this.currentArea.Last48HoursIn.toString().split('.')[0];
      }
    },
    snowSeasonTotal() {
      if (!this.activeWeatherZone) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.snow_report.SeasonTotalCm.toString().split('.')[0]
          : this.snow_report.SeasonTotalIn.toString().split('.')[0];
      }
    },
    stormTotal() {
      if (!this.activeWeatherZone) { return; }

      if (typeof (this.currentArea) !== 'undefined') {
        return this.snowbaseUnit === 'metric'
          ? this.snow_report.StormTotalCM.toString().split('.0')[0]
          : this.snow_report.StormTotalIn.toString().split('.0')[0];
      }
    },
    forecastDays() {
      if (this.forecast) {
        return [
          this.forecast.OneDay,
          this.forecast.TwoDay,
          this.forecast.ThreeDay,
          this.forecast.FourDay,
          this.forecast.FiveDay,
        ];
      }
      return [];
    },
    measureInMetric() {
      return this.snowbaseUnit == 'metric';
    },
    forecastedDaySnow() {
      return this.day
        ? this.snowbaseUnit === 'metric' ? this.forecastedDayCm : this.forecastedDayIn
        : 0;
    },
    forecastedDayCm() {
      return `${this.forecast.ForecastedSnowCm}`;
    },
    forecastedDayIn() {
      return `${this.forecast.ForecastedSnowIn}`;
    },
    // forecastedNightSnow() {
    //   return this.day
    //     ? this.snowbaseUnit === 'metric' ? this.forecastedDayCm : this.forecastedDayIn
    //     : 0;
    // },
    // forecastedNightCm() {
    //   return `${this.day.forecasted_snow_night_cm} cm`;
    // },
    // forecastedNightIn() {
    //   return `${this.day.forecasted_snow_night_in} in`;
    // },
    ...mapState([
      'activeWeatherZone',
      'weatherZones',
      'snow_report',
      'forecast',
      'snowbaseUnit',
    ]),
    ...mapGetters(['weatherLayoutOptions']),
  },

  methods: {
    toggleStormRadar() {
      if (this.snow_report.StormRadar === '--' ) { return false; }

      this.showStormRadar = true;
    },
    closeStormModal() {
      this.showStormRadar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .weather {
    .flex-grid {
      @media screen and (max-width: 1400px) {
        flex-flow: wrap;
        > .col {
          flex-grow: 0;

          &.flex-grow-1 {
            flex-grow: 1;
          }
        }
      }
    }

    .toggle-wrappers {

      @media (min-width: 900px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }

  .forecast-wrapper {
    border: .5px solid $brand-blue;
    margin-bottom: 30px;

    @media (min-width: 900px) {
      margin-bottom: 40px;
    }

    .titles {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .swipe {
        color: $brand-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $base-font-bold;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 1.2;
        margin: 0;

        @media screen and (min-width: $tablet) {
          display: none;
        }

        img {
          margin-right: 5px;
        }
      }

      .title {
        color: $brand-blue;
        display: block;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.2;
        margin: 0;
        text-transform: uppercase;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }
    }
  }

  .current-weather-block,
  .snow-depth-block {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    @media screen and (min-width: $tablet) {
      overflow-x: hidden;
      overflow-y: hidden;
    }

    p {
      font-family: $base-font-regular;
      font-size: 14px;
    }

    .snowfall-day {
      color: #201547;
      font-family: $base-font-regular;
      font-size: 12px;
      min-width: 180px;
      height: 100%;
      align-self: flex-start;
      flex: 0 0 20%;
      margin-right: 1.5%;
      padding-right: 1.5%;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 16px;
      }
    }
    //.current {
    //  flex: 0 100%;
    //}
  }


  .weather {
    .flex-grid > {
      .forecast-snowfall-block {
        background-color: $body-bg;
        margin-bottom: -20px;
        flex-basis: 120%;
        margin: 0px -20px -20px;
        padding: 20px 0;
        order: 4;

        @media (min-width: $mobile) {
          background-color: transparent;
          margin-bottom: 0;
          margin-top: 20px;
          padding: 0;
          flex-basis: 100%;
        }
        @media screen and (min-width: 1300px) {
          flex-basis: 45%;
          order: 3;
        }
        @media screen and (min-width: 1400px) {
          flex-basis: 55%;
        }
        @media screen and (min-width: 1500px) {
          margin-top: 20px;
          flex-basis: 32%;
          order: 4;
        }
      }
    }
  }

  .snow-report-block {
    color: #201547;
    font-family: $base-font-regular;
    font-size: 12px;
    min-width: 150px;
    height: 100%;
    align-self: flex-start;
    flex: 0 1 20%;
    margin-right: 1.5%;
    padding-right: 1.5%;

    @media screen and (min-width: $tablet) {
      font-size: 14px;
      min-width: 200px;
      flex: 0 1 25%;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 16px;
    }

    &.storms {
      min-width: 200px;
      flex: 0 1 25%;

      @media screen and (min-width: $tablet) {
        min-width: 200px;
        flex: 0 1 25%;
      }
    }
  }

  .snowbase,
  .stormbase {
    height: 100%;
    //border-left: .5px solid $brand-blue;
    color: $brand-blue;
    font-size: 12px;
    padding: 15px 20px;
    text-align: left;

    @media screen and (min-width: $tablet) {
      font-size: 14px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 15px;
    }

    h4 {
      font-family: $font-sans;
      color: $brand-blue;
      text-align: left;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 0px;

      @media screen and (min-width: $tablet) {
        font-size: 13px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 14px;
      }
    }
    .totals {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 30px;
      font-weight: 900;
      line-height: 1;
      font-family: $headline-font-regular;
      height: 50px;

      @media screen and (min-width: $tablet) {
        font-size: 40px;
        height: 65px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 50px;
        text-align: center;
        height: 80px;
      }

      .weather-icon {
        font-size: 50px;
        color: $brand-blue;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        height: 50px;

        @media screen and (min-width: $tablet) {
          font-size: 65px;
          height: 65px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 80px;
          height: 80px;
        }
      }

      .depth {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 30px;
        font-weight: 900;
        line-height: 1;
        font-family: $headline-font-regular;
        margin-left: 10px;

        @media screen and (min-width: $tablet) {
          font-size: 40px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 50px;
          text-align: center;
        }
      }

      .metric {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 5px;
      }

      .unit {
        text-transform: uppercase;
        margin: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;

        @media screen and (min-width: $tablet) {
          font-size: 18px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .details {
      .detail {
        color: $brand-blue;
        font-size: 10px;
        line-height: 1;
        margin: 5px 0;


        @media screen and (min-width: $tablet) {
          font-size: 13px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
