<template>
  <div class="trail" v-if="trailDetails">
    <div class="trail-title-box">
      <div class="trail-name">{{trailDetails.Name}}</div>
      <div class="trail-status" :class="{
        'closed': hasClosedStatus,
        'open': hasOpenStatus,
        'on-hold': hasHoldStatus,
        'scheduled': hasScheduledStatus
      }"><span>{{trailDetails.Status}}</span></div>
      <TrailIcons :trail="trailDetails" />
      <div class="rating" :class="ratingClass"></div>
      <div class="trail-message">{{trailDetails.Status}}</div>
    </div>
  </div>
  <div class="trail col no-stretch keep-padding" v-else>
    <div class="trail-title-box">
      <div class="trail-name">Loading</div>
      <div class="trail-status"><span></span>Loading</div>
      <div class="rating"></div>
      <div class="trail-message"><span></span>Loading</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TrailIcons from '@/components/MountainAreas/TrailIcons.vue';
import MobileTrailIcons from '@/components/MountainAreas/MobileTrailIcons.vue';

export default {
  name: 'Trail',
  props: ['trail', 'index'],
  components: {
    TrailIcons,
    MobileTrailIcons,
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapState(['trails', 'mountainAreas', 'mtnAreaNavSections', 'mtnTrailsSorting']),
    ...mapGetters(['closedStatuses', 'openStatuses', 'holdStatuses', 'scheduledStatuses', 'mtnAreaToggles', 'getTrailDetails']),
    ratingClass() {
      return `rating-${this.trailDetails.Difficulty.toLowerCase().replace(' ', '-')}`;
    },
    trailClass() {
      return this.trailDetails.StatusEnglish;
    },
    hasClosedStatus() {
      return this.closedStatuses.indexOf(this.trailDetails.StatusEnglish) >= 0;
    },
    hasOpenStatus() {
      return this.openStatuses.indexOf(this.trailDetails.StatusEnglish) >= 0;
    },
    hasHoldStatus() {
      return this.holdStatuses.indexOf(this.trailDetails.StatusEnglish) >= 0;
    },
    hasScheduledStatus() {
      return this.scheduledStatuses.indexOf(this.trailDetails.StatusEnglish) >= 0;
    },
    // showTrail() {
    //   if( this.mtnAreaToggles.groomingValue !== true) {
    //     return this.hasOpenStatus
    //       ? true
    //       : this.mtnAreaToggles.closedTrailsValue;
    //   } else {
    //     return this.hasOpenStatus && this.trailsIsGroomed();
    //   }
    // },
    expandable() {
      return !this.hasClosedStatus;
    },
    // eslint-disable-next-line consistent-return
    // getAreas() {
    //   if (this.area) {
    //     const areaName = this.area.Name;
    //     return this.getAreaDetails(areaName);
    //   }
    // },
    trailDetails() {
      if (this.trail) {
        const trailName = this.trail.Name;
        return this.getTrailDetails(trailName);
      }
    },
  },
  methods: {
    // toggleExpanded() {
    //   this.expanded = !this.expanded;
    // },
    // trailsIsGroomed() {
    //   return this.trailDetails.Grooming !== '--' && this.trailDetails.Grooming !== 'No'
    // }
  },
};
</script>

<style lang="scss" scoped>
  #dashboard {
    .trail {
      background-color: $white;
      width: 100%;
      border-radius: 0;
      display: flex;
      justify-content: stretch;

      .trail-title-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        min-height: 50px;
        border-top: 0.5px solid $brand-blue;
        flex: 1 100%;
      }

      .trail-name,
      .rating,
      .trail-status,
      .trail-message {
        color: $brand-gray;
        display: block;
        font-family: $base-font-regular;
        font-size: 12px;
        line-height: 1.2;
        overflow: hidden;
        white-space: nowrap;
        padding: 10px;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
          letter-spacing: 0.2px;
        }

        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }

      .trail-name {
        flex: 1 auto;
        min-width: 150px;
      }

      .rating {
        margin: 0;
        background-size: 60px;

        &.rating-expert {
          background-size: 85px;
          height: 30px;
          width: 30px;
        }
      }

      .trail-icons {
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.15px;
        padding: 0 10px;
        display: flex;
      }

      .trail-status {
        display: block;

        span {
          width: 16px;
          height: 16px;
          display: inline-block;
          overflow: hidden;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          padding: 0;
          margin-right: 5px;
          line-height: 1;
          text-indent: -9999px;

          @media screen and (min-width: $tablet) {
            width: 18px;
            height: 18px;
          }
        }
      }

      .trail-status,
      .trail-icons,
      .rating {
        text-align: center;
        flex: 0 100px;
        min-width: 100px;
      }
      .trail-icons{
        flex: 0 130px;
        min-width: 130px;
      }

      .trail-message {
        display: block;
        text-align: left;
        flex: 0 200px;
        min-width: 200px;
      }
    }
  }
</style>
