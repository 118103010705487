/* eslint-disable camelcase, no-console */

export default {
  POPULATE_RESORT(state, resort) {
    state.activeMountainArea = resort.MountainAreas.filter(area => state.activeMountainAreaName == area.Name)[0];
    state.resort = resort;
    state.snow_report = resort.SnowReport;
    state.mountainAreas = resort.MountainAreas;
    state.lifts = state.mountainAreas.map(area => area.Lifts).flat();
    state.trails = state.mountainAreas.map(area => area.Trails).flat();
    state.shuttles = state.mountainAreas.map(area => area.Activities).flat();
    state.status = '';
    state.weatherLayoutOptions = resort.LayoutOptions.Weather;
    state.legalDisclaimer = resort.LayoutOptions.Disclaimer;
  },
  // POPULATE_LIFTS(state, resort_lifts_feed) {
  //   state.lifts =  resort_lifts_feed;
  // },
  POPULATE_WEATHER(state, weather) {
    state.weather = weather;
    state.forecast = weather.Forecast;
    if (state.defaultWeatherZones.length === 3) {
      state.weatherZones = weather.CurrentConditions;
    } else {
      state.weatherZones = weather.CurrentConditions.filter(wz => state.defaultWeatherZones.indexOf(wz.Location) >= 0);
    }
    state.activeWeatherZone = weather.CurrentConditions.filter(wz => state.defaultWeatherZone === wz.Location)[0];
  },
  POPULATE_WEBCAMS(state, webcams) {
    state.webcams = webcams.WebCams;
  },
  POPULATE_WAITS(state, waits) {
    state.waits = waits.info.lift_info;
  },
  API_FAILURE(state, error) {
    console.error(error);
    state.status = 'Please refresh to try again. If this continues, please wait 5 minutes and try again.';
  },
  SET_ACTIVE_MOUNTAIN_AREA(state, mountainArea) {
    state.activeMountainArea = mountainArea;
  },
  SET_ACTIVE_MOUNTAIN_SECTION(state, mountainSection) {
    state.activeMountainSection = mountainSection;
  },
  SET_ACTIVE_WEATHER_ZONE(state, zone) {
    state.activeWeatherZone = state.weather.CurrentConditions.filter(wz => zone === wz.Location)[0];
  },
  SET_ACTIVE_DASHBOARD_SECTION(state, section) {
    state.activeDashboardSection = section;
  },
  SET_RESORT_TO_METRIC(state, metric) {
    state.defaultToMetric = metric;
  },
  SET_SNOWBASE_UNIT(state, unit) {
    state.snowbaseUnit = unit;
  },
  SET_TEMPERATURE_UNIT(state, unit) {
    state.temperatureUnit = unit;
  },
  SET_WINDSPEED_UNIT(state, unit) {
    state.windspeedUnit = unit;
  },
};
