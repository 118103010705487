import { render, staticRenderFns } from "./Shuttle.vue?vue&type=template&id=5e2e45f2&scoped=true&"
import script from "./Shuttle.vue?vue&type=script&lang=js&"
export * from "./Shuttle.vue?vue&type=script&lang=js&"
import style0 from "./Shuttle.vue?vue&type=style&index=0&id=5e2e45f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2e45f2",
  null
  
)

export default component.exports