<template>
  <div id="news-alert" class="full-width-container hide-tablet">
    <div class="alert-bar-rectangle" :class="alertBarClasses" v-if="alertPresent">
      <div class="wrapper">
        <div title="View Alerts" @click.prevent="toggleExpanded" :class="{ 'clickable' : shortText }">
          <div class="bar-alert-icon"></div>
          <div class="alert-bar-expand-icon" :class="{'expanded-active': expanded}" v-if="shortText"></div>
          <h2 class="ttu" v-if="shortText">{{shortText}}</h2>
          <p class="alert-bar" v-if="!shortText">
            {{ alert }}
          </p>
        </div>
      </div>
      <div class="expanded">
        <span v-if="expanded" v-html="expandedText"></span>
        <br />
        <a href="#" class="alert-button" v-if="showAlertButton" @click.prevent="toggleStormRadar">
          <span class="text">{{ buttonText }}</span>
          <span class="arrow"></span>
        </a>
      </div>
    </div>

    <StormRadar :embed="alertBtn" v-if="showStormRadar && showAlertButton" v-on:closeStormModal="closeStormModal"></StormRadar>

  </div>
</template>

<script>
import StormRadar from './Weather/StormRadar.vue';

export default {
  name: 'Alert',
  props: ['alert', 'alertBtn', 'alertBtnText'],
  components: { StormRadar },
  data() {
    return {
      expanded: false,
      showStormRadar: false
    };
  },
  mounted() {
    if (this.$route.path.length > 0 && /expand-alert/.test(this.$route.path)) {
      this.expanded = true;
    }
  },
  computed: {
    alertPresent() {
      // There is a feed update that changed null to ---
      return typeof (this.alert) !== 'undefined' && this.alert !== '--';
    },
    showAlertButton() {
      // There is a feed update that changed null to ---
      return typeof (this.alertBtn) !== 'undefined' && this.alertBtn !== '--';
    },
    shortText() {
      if (this.alert.indexOf('...more...') < 0) { return; }

      if (this.alertPresent) {
        return this.alert.split('...more...')[0];
      }
    },
    expandedText() {
      if (this.alertPresent) {
        return this.alert.split('...more...')[1];
      }
    },
    alertBarClasses() {
      if (this.expanded) { return ' news-bar-expanded'; }
    },
    buttonText() {
      if (typeof (this.alertBtnText) === 'undefined' || this.alertBtnText === '--') {
        return 'View Storm Radar'; // default
      }

      return this.alertBtnText;
    }
  },
  methods: {
    toggleExpanded() {
      if (this.alertPresent && (this.expandedText || window.innerWidth <= 768)) {
        this.expanded = !this.expanded;
      }
    },
    toggleStormRadar() {
      if (!this.showAlertButton) { return false; }

      this.showStormRadar = true;
    },
    closeStormModal() {
      this.showStormRadar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-bar-rectangle {
  background: $alert-bar-background;
  background-image: none;
  background-position: top .4em right;
  background-repeat: no-repeat;
  background-size: 2.5em;
  color: $white;
  font-family: $base-font-regular;
  font-size: 16px;
  letter-spacing: 0.44px;
  margin-bottom: 0;
  max-height: 65px;
  overflow-y: hidden;
  padding: 20px 15px 20px 10px;
  transition: all .15s ease-in;
  @media (min-width: $tablet) {
    margin: 0 0 1em;
    padding: 20px 35px;
  }
  .clickable {
    cursor: pointer;
  }
  &.news-bar-expanded {
    max-height: 500px;

    .expanded {
      margin-top: 20px;
      max-height: 400px;
      padding-bottom: 30px;
    }
  }

  h2 {
    max-height: 35px;
    overflow: hidden;
    padding-top: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (min-width: $tablet) {
      max-height: 30px;
      padding-top: 2px;
    }
  }
  h2, p.alert-bar {
    color: $white;
    font-family: $headline-font-bold;
    font-size: 15px;
    font-weight: $headline-font-weight;
    line-height: 1.2;
    @media (min-width: $tablet) {
      font-family: $headline-font-regular;
      font-size: 23px;
      font-weight: 500;
    }
  }

  p.alert-bar {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.44px;
    line-height: 1.2;
    @media (min-width: $tablet) {
      padding-top: 4px;
    }
  }

  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: $white;
    }
  }
  a.alert-button {
    background-color: transparent;
    border: solid 2px $white;
    color: $white;
    display: inline-block;
    font-family: $base-font-regular;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.2px;
    margin: 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
    span {
      transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
      box-shadow: none;
    }
    &:hover {
      background-color: $white;
      span.text {
        color: $alert-bar-background;
      }
    }
    span.text {
      display: inline-block;
      height: 32px;
      margin-top: -2px;
      padding: 5px 25px;
      position: relative;
      top: 2px;
    }
    span.arrow {
      background: $white url('~@/assets/icons/right-arrow.svg') no-repeat center center;
      display: inline-block;
      height: 32px;
      margin: 0;
      padding: 5px 10px;
      vertical-align: middle;
    }
  }

  // make sure entire alert is clickable
  .wrapper > a {
    display: block;
    min-height: 1.5em;
    text-decoration: none;
    width: 100%;
    &:hover {
      color: $white;
    }
  }
}

.bar-alert-icon {
  background: url('~@/assets/icons/snowflake.svg') no-repeat center center;
  background-size: cover;
  float: left;
  height: 30px;
  margin-right: 5px;
  margin-top: -3px;
  width: 30px;
  @media screen and (min-width: $tablet) {
    margin-right: 10px;
    height: 35px;
    width: 35px;
  }
}

.alert-bar-expand-icon {
  background: url('https://www.mtnfeed.com/images/down-arrow.svg') no-repeat center center;
  background-size: cover;
  float: right;
  height: 12px;
  margin-left: 10px;
  margin-top: 6px;
  width: 17px;

  &:active, &.expanded-active {
    background: url('https://www.mtnfeed.com/images/up-arrow.svg') no-repeat center center;
    background-size: cover;
  }
  @media screen and (min-width: $tablet) {
    height: 15px;
    margin-left: 20px;
    width: 25px;
  }
}
</style>
