<template>
  <div class="mobile-trail-icons" :class="{'expanded': expandable}">
    <div class="trail-tile" v-if="showGrooming">
      <div class="trail-icon icon--grooming">
        <img :src="require(`@/assets/icons/dark/grooming_${groomingIcon}.svg`)" alt="Grooming Icon" class="icon" />
      </div>
      <div class="trail-label">{{groomingStatus}}</div>
    </div>
    <div class="trail-tile" v-if="showSnowmaking">
      <div class="trail-icon icon--snowmaking">
        <img :src="require(`@/assets/icons/dark/snowmaking_${snowmakingIcon}.svg`)" alt="Snowmaking Icon" class="icon" />
      </div>
      <div class="trail-label">{{snowmakingStatus}}</div>
    </div>
    <div class="trail-tile" v-if="showGlades">
      <div class="trail-icon icon--glades">
        <img :src="require(`@/assets/icons/dark/glades_${gladesIcon}.svg`)" alt="Glades Icon" class="icon" />
      </div>
      <div class="trail-label">{{gladesStatus}}</div>
    </div>
    <div class="trail-tile" v-if="showMoguls">
      <div class="trail-icon icon--moguls">
        <img :src="require(`@/assets/icons/dark/moguls_${mogulsIcon}.svg`)" alt="Moguls Icon" class="icon" />
      </div>
      <div class="trail-label">{{mogulsStatus}}</div>
    </div>
    <div class="trail-tile" v-if="showNightskiing">
      <div class="trail-icon icon--nightskiing">
        <img :src="require(`@/assets/icons/dark/nightskiing_${nightSkiingIcon}.svg`)" alt="NightSkiing Icon" class="icon" />
      </div>
      <div class="trail-label">{{nightskiingStatus}}</div>
    </div>
    <div class="trail-tile" v-if="showGroomersChoice">
      <div class="trail-icon icon--groomers_choice">
        <img :src="require(`@/assets/icons/dark/groomers_choice.svg`)" alt="Groomer's Choice Icon" class="icon" />
      </div>
      <div class="trail-label">{{groomersChoiceStatus}}</div>
    </div>
    <div class="trail-tile" v-if="showTouring">
      <div class="trail-icon icon--touring">
        <img :src="require(`@/assets/icons/dark/${touringIcon}.svg`)" alt="Touring Icon" class="icon" />
      </div>
      <div class="trail-label">{{touringStatus}}</div>
    </div>
    <div class="trail-tile" v-if="showNordic">
      <div class="trail-icon icon--nordic">
        <img :src="require(`@/assets/icons/dark/nordic_${nordicIcon}.svg`)" alt="Nordic Icon" class="icon" />
      </div>
      <div class="trail-label">{{nordicStatus}}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MobileTrailIcons',
  props: ['trail', 'expanded'],
  computed: {
    ...mapGetters(['filterIcons', 'customIcons', 'resortSluggedName']),
    groomingIcon() {
      return this.trail.Grooming.toLowerCase().split(' ').join('_').replace('grooming_', '');
    },
    snowmakingIcon() {
      return this.trail.SnowMaking.toLowerCase().split(' ').join('_').replace('snowmaking_', '');
    },
    gladesIcon() {
      return this.trail.Glades.toLowerCase().split(' ').join('_').replace('glades_', '');
    },
    mogulsIcon() {
      return this.trail.Moguls.toLowerCase().split(' ').join('_').replace('moguls_', '');
    },
    touringIcon() {
      const touringIcon = this.trail.Touring.toLowerCase().split(' ').join('_').replace('touring_', '')

      if( this.customIcons.indexOf('touring') >= 0 ) {
        return `resorts/${this.resortSluggedName}/touring_${touringIcon}`;
      }

      return `touring_${touringIcon}`;
    },
    nordicIcon() {
      return this.trail.Nordic.toLowerCase().split(' ').join('_').replace('nordic_', '');
    },
    nightSkiingIcon() {
      return this.trail.NightSkiing.toLowerCase().split(' ').join('_').replace('nightskiing_', '');
    },
    gladesStatus() {
      if (this.trail.Glades) {
        return `Glades ${this.trail.Glades.replace('Yes', '')}`;
      }
      return 'N/A';
    },
    mogulsStatus() {
      if (this.trail.Moguls) {
        return `Moguls ${this.trail.Moguls.replace('Yes', '')}`;
      }
      return 'N/A';
    },
    nightskiingStatus() {
      if (this.trail.NightSkiing) {
        return `Night Skiing ${this.trail.NightSkiing.replace('Yes', '')}`;
      }
      return 'N/A';
    },
    groomersChoiceStatus() {
      if (this.trail.StatusEnglish === 'groomers_choice') {
        return "Groomer's Choice";
      }
      return 'N/A';
    },
    groomingStatus() {
      if (this.trail.Grooming) {
        return `Grooming ${this.trail.Grooming.replace('Yes', '')}`;
      }
      return 'N/A';
    },
    snowmakingStatus() {
      if (this.trail.SnowMaking) {
        if (this.trail.SnowMaking == 'Overnight') {
          return 'Night Snowmaking';
        }
        return `Snowmaking ${this.trail.SnowMaking.replace('Yes', '')}`;
      }
      return 'N/A';
    },
    touringStatus() {
      if (this.trail.Touring) {
        return this.trail.Touring.replace('touring_', 'Touring ').replace('Yes', 'Touring');
      }
      return 'N/A';
    },
    nordicStatus() {
      if (this.trail.Nordic) {
        return this.trail.Nordic.replace('nordic_', 'Nordic ').replace('Yes', 'Nordic');
      }
      return 'N/A';
    },
    showGrooming() {
      return this.filterIcons.grooming && (this.trail.Grooming && !/No/i.test(this.trail.Grooming));
    },
    showSnowmaking() {
      return this.filterIcons.snowmaking && (this.trail.SnowMaking && !/No/i.test(this.trail.SnowMaking));
    },
    showMoguls() {
      return this.filterIcons.moguls && (this.trail.Moguls && !/No/i.test(this.trail.Moguls));
    },
    showGlades() {
      return this.filterIcons.glades && (this.trail.Glades && !/No/i.test(this.trail.Glades));
    },
    showNightskiing() {
      return this.filterIcons.nightskiing && (this.trail.NightSkiing && !/No/i.test(this.trail.NightSkiing));
    },
    showGroomersChoice() {
      return this.trail.StatusEnglish === 'groomers_choice';
    },
    showTouring() {
      return this.filterIcons.touring && (this.trail.Touring && /Yes/i.test(this.trail.Touring));
    },
    showNordic() {
      return this.filterIcons.nordic && (this.trail.Nordic && /Yes/i.test(this.trail.Nordic));
    },
    expandable() {
      if (this.showGrooming || this.showSnowmaking || this.showMoguls || this.showGlades || this.showNightskiing || this.showTouring) {
        return this.expanded == true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .mobile-trail-icons {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    max-height: 0;
    opacity: 0;
    padding-top: 5px;
    position: relative;
    transition: all .25s ease-in-out;
    &.expanded {
      max-height: 200px;
      &:before {
        content: "";
        border-top: .5px solid $body-bg;
        position: absolute;
        left: 5%;
        top: -5px;
        width: 90%;
      }
      opacity: 1;
      @media (min-width: $tablet) {
        display: none;
      }
    }
    .trail-tile {
      align-self: flex-end;
      flex: 1;
      margin: 0 1% 5px;
      flex-basis: 45%;
      max-width: 45%;
      padding: 0px 5%;
      white-space: nowrap;
      .trail-label, .trail-icon {
        color: $dark-gray;
        display: inline-block;
        font-family: $base-font-regular;
        font-size: 12px;
        letter-spacing: 0.11px;
        opacity: 0.7;
        vertical-align: text-top;
      }
    }
    .trail-icon {
      display: inline-block;
      margin-right: -5px;
      vertical-align: middle;
      width: auto;
      &:last-child {
        margin-right: 5px;
      }
      @media (min-width: $tablet) {
        margin-right: 10px;
      }
      .icon {
        display: inline-block;
        height: auto;
        margin: -5px 5px 0 0;
        max-width: initial;
        position: relative;
        vertical-align: middle;
        width: 30px;
        @media (min-width: $tablet) {
          display: block;
        }
      }
      &.icon--snowmaking {
        .icon {
          @media (min-width: $tablet) {
          }
        }
      }
      &.icon--grooming {
        .icon {
          @media (min-width: $tablet) {
          }
        }
      }
      &.icon--glades {
        .icon {
          @media (min-width: $tablet) {
          }
        }
      }
      &.icon--moguls {
        .icon {
          @media (min-width: $tablet) {
          }
        }
      }
      &.icon--touring {
        .icon {
          @media (min-width: $tablet) {
          }
        }
      }
      &.icon--nightskiing {
        .icon {
          @media (min-width: $tablet) {
          }
        }
      }
    }
  }
  .closed-trail {
    .trail-icons {
      display: none;
    }
  }
}
</style>
