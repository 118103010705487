<template>
  <div class="mountain-area-sections">
    <Lifts v-show="checkActiveSection('lifts')" />
    <Trails v-show="checkActiveSection('trails')" />
    <Parks v-show="checkActiveSection('parks')" />
    <Kids v-show="checkActiveSection('kids')" />
    <Shuttles v-show="checkActiveSection('shuttles')" />
    <Maps v-show="checkActiveSection('maps')" />
    <Webcams v-if="checkActiveSection('webcams')" />
    <Weather v-if="checkActiveSection('weather')" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Trails from '@/components/MountainAreas/Trails.vue';
import Lifts from '@/components/MountainAreas/Lifts.vue';
import Maps from '@/components/MountainAreas/Maps.vue';
import Parks from '@/components/MountainAreas/Parks.vue';
import Kids from '@/components/MountainAreas/Kids.vue';
import Shuttles from '@/components/MountainAreas/Shuttles.vue';
import Weather from '@/components/Weather.vue';
import Webcams from '@/components/Webcams.vue';
// import WebcamsHooper from '@/components/WebcamsHooper.vue';

export default {
  name: 'Sections',
  components: {
    Weather,
    Webcams,
    // WebcamsHooper,
    Trails,
    Lifts,
    Maps,
    Parks,
    Kids,
    Shuttles,
  },
  data() {
    return { };
  },
  computed: mapState(['activeMountainArea', 'activeMountainSection']),
  methods: {
    checkActiveSection(section_name) {
      // var route_name = this.$route.path.replace("/", "")
      return this.activeMountainSection == section_name;
    },
  },
};
</script>

<style lang="scss" scoped>
  #dashboard {
    .mountain-area-sections {
      position: relative;
    }
    .sections {

    }
  }
</style>
