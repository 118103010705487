<template >
  <div class="mountain-section trails" id="all-area-trails">
    <div v-if="sortedAreas">
      <div class="area-wrapper" v-for="(area, index) in sortedAreas">
        <div class="mountain-area" v-if="area.Trails[0]">
          <div class="titles">
            <h1 class="area-name ttu">{{area.Name}}</h1>
            <span class="swipe"><img :src="require(`@/assets/icons/arrow-right-arrow-left.svg`)" alt="Swipe for more" class="icon" height="14px" />SCROLL</span>
          </div>
          <div class="scroll-wrapper">
            <div class="trail-heading">
              <div class="trail-name">Trail Name</div>
              <div class="trail-status">Status</div>
              <div class="trail-icons">Condition</div>
              <div class="trail-difficulty">Rating</div>
              <div class="trail-message">Message</div>
            </div>
            <Trail
              v-for="(trail, index) in area.Trails"
              :index="index"
              :trail="trail"
              :key="`trail-${ trail.Name }-${ index }`">
            </Trail>
          </div>
        </div>
      </div>
    </div>
    <div v-else> Loading ...</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Trail from '@/components/MountainAreas/Trail.vue';

export default {
  name: 'Trails',
  components: {
    Trail,
  },
  computed: {
    // trailMountainAreas() {
    //   if( this.mtnAreaNavSections.indexOf('trails') < 0 ) {
    //     return this.mountainAreas;
    //   }
    //
    //   return this.currentActiveMountainArea
    //     ? [this.currentActiveMountainArea]
    //     : this.mountainAreas;
    // },
    sortedAreas() {
      return this.mtnTrailsSorting.area;
    },
    getAreas() {
      const areaName = this.a.Name;
      return this.getAreaDetails(areaName);
    },

    ...mapState(['mountainAreas', 'mtnAreaNavSections', 'mtnTrailsSorting']),
    ...mapGetters(['currentActiveMountainArea', 'openStatuses', 'getTrailsSorting']),
  },
  methods: {
    // openTrails(trails) {
    //   return trails.filter(trail => trail.ExcludeInTotal !== 'true' && this.openStatuses.indexOf(trail.StatusEnglish) >= 0).length;
    // },
    // totalTrails(trails) {
    //   return trails.filter(trail => trail.ExcludeInTotal !== 'true').length;
    // },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .trails {
    .mountain-area {
      border: .5px solid $brand-blue;
      margin-bottom: 30px;

      .scroll-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;

        @media screen and (min-width: $tablet) {
          overflow-x: hidden;
          overflow-y: hidden;
        }
      }

      .titles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .swipe {
          color: $brand-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $base-font-bold;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 1.2;
          margin: 0;

          @media screen and (min-width: $tablet) {
            display: none;
          }

          img {
            margin-right: 5px;
          }
        }
      }

      h1.area-name  {
        color: $brand-blue;
        display: block;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 1.2;
        margin: 0;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }
      .trail-counts {
        color: $dark-gray;
        display: inline-block;
        font-family: $base-font-regular;
        font-size: 16px;
        line-height: 1.2;
        strong {
          font-family: $base-font-bold;
          font-weight: $headline-font-weight;
        }
      }
    }

    .trail-heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .trail-name,
      .trail-status,
      .trail-icons,
      .trail-message,
      .trail-difficulty {
        color: $brand-blue;
        display: block;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        font-size: 10px;
        line-height: 1.2;
        margin: 0;
        text-transform: uppercase;
        padding: 10px;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
          letter-spacing: 0.5px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }

      .trail-name {
        flex: 1 auto;
        min-width: 150px;
      }

      .trail-status,
      .trail-icons,
      .trail-difficulty {
        text-align: center;
        flex: 0 100px;
        min-width: 100px;
      }
      .trail-icons{
        flex: 0 130px;
        min-width: 130px;
      }

      .trail-message {
        text-align: left;
        flex: 0 200px;
        min-width: 200px;
      }

    }
  }
}
</style>
