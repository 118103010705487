<template>
  <div class="park col no-stretch keep-padding" v-if="parkDetails">
    <div class="park-title-box">
      <div class="park-name">{{parkDetails.Name}}</div>
      <div class="park-status" :class="{
        'closed': hasClosedStatus,
        'open': hasOpenStatus,
        'on-hold': hasHoldStatus,
        'scheduled': hasScheduledStatus
      }"><span>{{parkDetails.Status}}</span></div>
        <TrailIcons :trail="parkDetails" />
      <div class="rating" :class="ratingClass"></div>
      <div class="park-message">{{parkDetails.Status}}</div>
    </div>
  </div>
  <span v-else><!-- no show trail for transition-group --> </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TrailIcons from '@/components/MountainAreas/TrailIcons';

export default {
  name: 'Park',
  props: ['park', 'index'],
  components: {
    TrailIcons,
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapState(['lifts', 'trails', 'mountainAreas']),
    ...mapGetters(['closedStatuses', 'openStatuses', 'holdStatuses', 'scheduledStatuses', 'mtnAreaToggles', 'getTrailDetails']),
    ratingClass() {
      return `rating-${this.parkDetails.Difficulty.toLowerCase().replace(' ', '-')}`;
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    parkDetails() {
      if (this.park) {
        const trailName = this.park.Name;
        return this.getTrailDetails(trailName);
      }
    },
    // trailClass() {
    //   return this.parkDetails.StatusEnglish;
    // },
    hasClosedStatus() {
      return this.closedStatuses.indexOf(this.parkDetails.StatusEnglish) >= 0;
    },
    hasOpenStatus() {
      return this.openStatuses.indexOf(this.parkDetails.StatusEnglish) >= 0;
    },
    hasHoldStatus() {
      return this.holdStatuses.indexOf(this.parkDetails.StatusEnglish) >= 0;
    },
    hasScheduledStatus() {
      return this.scheduledStatuses.indexOf(this.parkDetails.StatusEnglish) >= 0;
    },
    // showTrail() {
    //   if( this.mtnAreaToggles.groomingValue !== true) {
    //     return this.hasOpenStatus
    //       ? true
    //       : this.mtnAreaToggles.closedTrailsValue;
    //   } else {
    //     return this.hasOpenStatus && this.trailsIsGroomed();
    //   }
    // },
    // expandable() {
    //   return !this.hasClosedStatus;
    // },
  },
  methods: {
    // toggleExpanded() {
    //   this.expanded = !this.expanded;
    // },
    // trailsIsGroomed() {
    //   return this.park.Grooming !== '--' && this.park.Grooming !== 'No'
    // }
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .park {
    background-color: $white;
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: stretch;

    .park-title-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 50px;
      border-top: 0.5px solid $brand-blue;
      flex: 1 100%;
    }

    .park-name,
    .trail-icons,
    .rating,
    .park-difficulty,
    .park-status,
    .park-message {
      color: $brand-gray;
      display: block;
      font-family: $base-font-regular;
      font-size: 12px;
      line-height: 1.2;
      overflow: hidden;
      white-space: nowrap;
      padding: 10px;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
        letter-spacing: 0.2px;
      }

      @media screen and (min-width: $tablet-landscape) {
        font-size: 16px;
      }
    }

    .trail-icons {
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.15px;
      text-align: center;
      padding: 0 10px;
      display: flex;
    }

    .rating {
      margin: 0;
    }

    .park-status {
      display: block;

      span {
        width: 16px;
        height: 16px;
        display: inline-block;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0;
        margin-right: 5px;
        line-height: 1;
        text-indent: -9999px;

        @media screen and (min-width: $tablet) {
          width: 18px;
          height: 18px;
        }
      }
    }
    .park-name {
      flex: 1 auto;
      min-width: 150px;
    }

    .park-status,
    .trail-icons,
    .rating {
      text-align: center;
      flex: 0 100px;
      min-width: 100px;
    }
    .trail-icons{
      flex: 0 130px;
      min-width: 130px;
    }

    .park-message {
      text-align: left;
      flex: 0 200px;
      min-width: 200px;
    }

  }
}
</style>
