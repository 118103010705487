<template>
  <div class="unit-toggles">
    <div class="temp-toggles" :class="{'show-metric': tempInMetric}">
      <a href="#metric" @click.prevent="toggleTempUnits('metric')" class="scale c">
        <span tabindex="-1">&ordm;C</span>
      </a>
      <a href="#imperial" @click.prevent="toggleTempUnits('imperial')" class="scale f">
        <span tabindex="-1">&ordm;F</span>
      </a>
    </div>

    <div class="depth-toggles" :class="{'show-metric': measureInMetric}">
      <a href="#snowbase-cm-metric" class="cm" @click.prevent="toggleDepthUnit('metric')">
        <span tabindex="-1">cm</span>
      </a>
      <a href="#snowbase-cm-imperial" class="in" @click.prevent="toggleDepthUnit('imperial')">
        <span tabindex="-1">in</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UnitToggles',
  props: [],
  components: { },
  computed: {
    tempInMetric() {
      return this.temperatureUnit == 'metric';
    },
    measureInMetric() {
      return this.snowbaseUnit == 'metric';
    },
    ...mapState(['temperatureUnit', 'snowbaseUnit']),
  },

  methods: {
    toggleTempUnits(unit) {
      this.$store.dispatch(
        'setWeatherUnits',
        {
          temperature_Unit: unit,
          windspeed_Unit: unit,
        },
      );
    },
    toggleDepthUnit(unit) {
      this.$store.dispatch('setWeatherUnits', { snowBase_Unit: unit });
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .unit-toggles {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;

    @media (min-width: 900px) {
      align-items: flex-end;
      margin-bottom: 40px;
    }


    .temp-toggles {
      margin-right: 20px;
    }

    .temp-toggles,
    .depth-toggles {
      display: flex;
      justify-content: flex-start;

      > a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        vertical-align: baseline;
        text-align: center;
        text-decoration: none;
        font-family: $headline-font-bold;
        font-weight: 700;
        width: 55px;
        letter-spacing: 1.4px;
        border: .5px solid $brand-blue;
        font-size: 12px;
        line-height: 1;
        padding: 12px 2px;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
          padding: 14px 4px;
        }

        @media screen and (min-width: $tablet-landscape) {
          font-size: 14px;
          padding: 16px 5px;
        }

        &.c,
        &.cm {
          background: #fff;
          color: $brand-blue;
        }

        &.f,
        &.in {
          background: $brand-blue;
          color: #fff;
        }
      }

      &.show-metric {
        > a {
          &.c,
          &.cm {
            background: $brand-blue;
            color: #fff;
          }

          &.f,
          &.in {
            background: #fff;
            color: $brand-blue;
          }
        }
      }
    }
  }
}
</style>
