<template>
  <div>
    <div class="scroll-point"></div>
    <div id="dashboard">
      <div class="background-container">
        <div id="top-section">
          <Alert
            :alert="snow_report.Alert"
            :alertBtn="snow_report.StormRadar"
            :alertBtnText="snow_report.StormRadarButtonText" />

          <div class="updatedTime">
            <label>Updated: </label>
            {{ formattedLastUpdate }}
          </div>
        </div>
        <div id="dashboard-blocks">
          <div id="dashboard-trails">
            <div id="dashboard-overview">
              <div v-if="checkActiveSection('weather')"></div>
              <div v-else-if="checkActiveSection('webcams')"></div>
              <div v-else>
                <TrailsOverview :snow_report="snow_report" />
              </div>
            </div>
            <div class="dashboard-navs">
              <SectionNavigation />
            </div>
          </div>
          <Sections class="dashboard-block plain-block" />
        </div>
      </div>
    </div>
<!--    v-if="checkActiveSection('weather')"-->
<!--    <div id="subnav-blocks">-->
<!--      <template v-for="(banner) in promoBanner">-->
<!--        <div class="subnav" :class="banner.name" v-if="banner.visible.indexOf(activeMountainSection) !== -1">-->
<!--        <a :href="banner.details.url" @click="scrollToElement">-->
<!--          <img :src="banner.details.img" :alt="banner.details.name" />-->
<!--        </a>-->
<!--          <h3><a :href="banner.details.url" @click="scrollToElement">{{ banner.details.name }}</a></h3>-->
<!--        <a :href="banner.details.url" class="button" @click="scrollToElement">-->
<!--          {{ banner.details.button }}-->
<!--        </a>-->
<!--        </div>-->
<!--      </template>-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ZoneSelector from '@/components/ZoneSelector.vue';
import Weather from '@/components/Weather.vue';
import Alert from '@/components/Alert.vue';
// import SnowfallChart from '@/components/SnowfallChart.vue';
import TrailsOverview from '@/components/TrailsOverview.vue';
import QuickLinks from '@/components/QuickLinks.vue';
import MountainAreaNavigation from '@/components/MountainAreas/Navigation.vue';
import Legend from '@/components/MountainAreas/Legend.vue';
import SectionNavigation from '@/components/SectionNavigation.vue';
import Sections from '@/components/Sections.vue';

export default {
  name: 'Dashboard',
  components: {
    Alert,
    ZoneSelector,
    Weather,
    // SnowfallChart,
    TrailsOverview,
    QuickLinks,
    Legend,
    SectionNavigation,
    MountainAreaNavigation,
    Sections,
  },
  computed: {
    ...mapState(['snow_report', 'activeDashboardSection', 'quickLinks', 'promoBanner', 'activeMountainArea', 'activeMountainSection']),
    ...mapGetters(['formattedLastUpdate']),
    // quickLinksPromoClass() {
    //   if (this.quickLinks && this.promoBanner) {
    //     return 'flex-grid flex-quicklinks-promo';
    //   }
    //   return '';
    // },
    // showOnPage() {
    //   return this.banner.visible.indexOf(this.activeMountainSection) >= 0;
    // },
  },
  methods: {
    checkActiveSection(section_name) {
      // var route_name = this.$route.path.replace("/", "")
      return this.activeMountainSection == section_name;
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('scroll-point')[0];

      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/site";

#dashboard {

  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: $tablet) {
    //border: 0.5px solid $brand-blue;
  }

  #top-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  #dashboard-blocks {
    display: flex;
    flex-flow: column;
    flex-direction: column;
    width: 100%;
    box-shadow: none;
    overflow: hidden;

    #main-col {
      width: 100%;
    }

    #side-col {
      background-color: $white;
      width: 100%;
      box-shadow: none;
    }
  }

  // For when both of these are showing
  .flex-quicklinks-promo {
    align-items: normal;

    .quick-links-container {
      background: $quicklinks-background;
      align-self: auto;
      flex-basis: 100%;
      padding: 25px 30px;

      @media (max-width: $tablet-landscape) {
        margin-right: 0;
      }
      @media (min-width: $tablet-landscape) {
        flex-basis: 30%;
      }
    }

    .promo-banner-container {
      flex-basis: 100%;
      @media (min-width: $tablet-landscape) {
        flex-basis: 68.5%;
      }
    }
  }

  .dashboard-navs {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
  }

  .dashboard-block {
    background-color: $white;
    width: 100%;
    padding: 20px 0;

    @media (min-width: $tablet) {
      padding: 40px 0;
    }


    &.plain-block {
      background-color: $body-bg;
      box-shadow: 0 0 0 0;
    }

    &.no-padding {
      padding: 0;
    }
  }
}

  #subnav-blocks {
    max-width: 1200px;
    margin: 0 auto 50px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    border: .5px solid $brand-blue;

    @media (min-width: $tablet) {
      flex-wrap: nowrap;
    }

    .subnav {
      flex: 1 0 100%;
      position: relative;
      padding: 1rem;
      border-bottom: .5px solid $brand-blue;

      @media (min-width: $tablet) {
        flex: 1 0 33%;
        border-bottom: 0;
        border-right: .5px solid $brand-blue;
        border-left: .5px solid $brand-blue;
      }
      @media (min-width: $tablet-landscape) {
        flex: 1 0 33%;
      }

      &:first-child,
      &:last-child {
        border-right-color: transparent;
        border-left-color: transparent;
      }

      &:last-child {
        border-bottom: 0;
      }

      a {
        display: block;
      }

      img {
        margin-bottom: 1rem;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 1.5rem;
        font-size: 16px;
        line-height: 1.1;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;

        @media (min-width: $tablet) {
          font-size: 20px;
        }
        @media (min-width: $tablet-landscape) {
          font-size: 24px;
        }

        a {
          color: $brand-blue;
          display: block;
          margin: 0;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 16px;
          line-height: 1.1;
          font-family: $base-font-bold;
          font-weight: $headline-font-weight;

          @media (min-width: $tablet) {
            font-size: 20px;
          }
          @media (min-width: $tablet-landscape) {
            font-size: 24px;
          }
        }
      }
      .button {
        display: inline-block;
        border: .5px solid $brand-blue;
        color: $brand-blue;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.4px;
        padding: 8px 12px;
        font-family: $base-font-bold;
        font-weight: 700;

        @media (min-width: $tablet) {
          font-size: 14px;
          padding: 10px 15px;
        }
      }
    }
  }


  //@media (min-width: $tablet-landscape) {
  //  #dashboard {
  //    #dashboard-blocks {
  //      flex-flow: wrap;
  //      #main-col {
  //        margin-right: 2%;
  //        width: 78%;
  //      }
  //
  //      #side-col {
  //        width: 20%;
  //      }
  //    }
  //    .dashboard-block {
  //      visibility: visible;
  //    }
  //  }
  //}
  .updatedTime {
    color: $dark-gray;
    font-family: $heading-font-family;
    text-align: right;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.5px;
    margin-right: 10px;
    padding-top: 5px;
    text-transform: uppercase;

    label {
      display: inline-block;
    }
  }
</style>
