<template>
  <div class="lift">
    <div class="lift-title-box" v-if="liftDetails">
      <div class="lift-name">{{ liftDetails.Name }}</div>
      <div class="lift-status" :class="{
        'closed': hasClosedStatus,
        'open': hasOpenStatus,
        'on-hold': hasHoldStatus,
        'scheduled': hasScheduledStatus
      }">
        <span>{{ liftDetails.Status }}</span>
      </div>
      <div class="lift-hours" :class="liftDetails.StatusEnglish">{{ liftHours }}</div>
      <div class="lift-wait" :class="liftDetails.StatusEnglish">
        {{ liftDetails.WaitTimeString }}</div>
      <div class="lift-message">
        {{ liftDetails.Status }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "Lift",
  props: ["lift", "index"],
  components: {},
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    ...mapState(['lifts', 'waits', 'mountainAreas']),
    ...mapGetters(['closedStatuses', 'openStatuses', 'holdStatuses', 'scheduledStatuses', 'getLiftDetails', 'getLiftWaits']),

    liftDetails() {
      if (this.lift) {
        const liftName = this.lift.Name;
        return this.getLiftDetails(liftName);
      }
    },
    liftWaitDetails() {
      if (this.lift) {
        const liftName = this.lift.Name;
        return this.getLiftWaits(liftName);
      }
    },
    // liftClass() {
    //   return this.liftDetails.StatusEnglish;
    // },
    hasClosedStatus() {
      return this.closedStatuses.indexOf(this.liftDetails.StatusEnglish) >= 0;
    },
    hasOpenStatus() {
      return this.openStatuses.indexOf(this.liftDetails.StatusEnglish) >= 0;
    },
    hasHoldStatus() {
      return this.holdStatuses.indexOf(this.liftDetails.StatusEnglish) >= 0;
    },
    hasScheduledStatus() {
      return this.scheduledStatuses.indexOf(this.liftDetails.StatusEnglish) >= 0;
    },
    liftHours() {
      if(this.hasHoldStatus || this.hasClosedStatus){
        return;
      }
      if (this.liftDetails.StatusEnglish === "anticipated_weather_impact") {
        return;
      }
      if (typeof this.liftDetails.Hours === "undefined") {
        return;
      }
      if (this.liftDetails.Hours === null) {
        return;
      }
      if (this.liftDetails.Hours === "--") {
        return;
      }

      const hours = this.liftDetails.Hours[this.todaysHours];

      if (hours.Open) {
        return `${hours.Open.replace(" AM", "am").replace(" PM", "pm")} - ${hours.Close.replace(
          " AM",
          "am"
        ).replace(" PM", "pm")}`;
      }
      return "";
    },
    todaysHours() {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const d = new Date();
      return days[d.getDay()];
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .lift {
    background-color: $white;
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: stretch;

    .lift-title-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 50px;
      border-top: .5px solid $brand-blue;
      flex: 1 100%;
    }

    .lift-name,
    .lift-status,
    .lift-wait,
    .lift-hours,
    .lift-message {
      color: $brand-gray;
      display: block;
      font-family: $base-font-regular;
      font-size: 12px;
      line-height: 1.2;
      padding: 10px;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
        letter-spacing: 0.2px;
      }

      @media screen and (min-width: $tablet-landscape) {
        font-size: 16px;
      }
    }

    .lift-status {
      display: block;

      span {
        width: 16px;
        height: 16px;
        display: inline-block;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0;
        margin-right: 5px;
        line-height: 1;
        text-indent: -9999px;

        @media screen and (min-width: $tablet) {
          width: 18px;
          height: 18px;
        }
      }
    }

    .lift-name {
      flex: 1 auto;
      min-width: 150px;
    }

    .lift-status {
      text-align: center;
      flex: 0 80px;
      min-width: 80px;
    }

    .lift-wait,
    .lift-hours {
      text-align: center;
      flex: 0 120px;
      min-width: 120px;

      @media screen and (min-width: $tablet) {
        flex: 0 150px;
        min-width: 150px;
      }
      @media screen and (min-width: $tablet-landscape) {
        flex: 0 180px;
        min-width: 180px;
      }
    }

    .lift-message {
      text-align: left;
      flex: 0 200px;
      min-width: 200px;
    }

  }
}
</style>
