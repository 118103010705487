<template >
  <div class="mountain-section trails" id="all-area-trails">
    <div class="mountain-area">
      <div class="titles">
        <h1 class="ttu">Shuttles</h1>
        <span class="swipe"><img :src="require(`@/assets/icons/arrow-right-arrow-left.svg`)" alt="Swipe for more" class="icon" height="14px" />SCROLL</span>
      </div>
      <div class="scroll-wrapper">
        <div class="shuttles">
          <div class="shuttle-heading">
            <div class="shuttle-name">Shuttle Name</div>
            <div class="shuttle-status">Status</div>
            <div class="shuttle-hours">Hours</div>
            <div class="shuttle-message">Message</div>
          </div>
        </div>
        <Shuttle v-for="(shuttle, index) in findShuttles" :index="index" :shuttle="shuttle" :key="shuttle.Name"
        ></Shuttle>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Shuttle from '@/components/MountainAreas/Shuttle.vue';

export default {
  name: 'Shuttles',
  components: {
    Shuttle,
  },
  computed: {
    // shuttleMountainAreas() {
    //   if (this.mtnAreaNavSections.indexOf('shuttles') < 0) {
    //     return this.mountainAreas;
    //   }
    //
    //   return this.currentActiveMountainArea
    //     ? [this.currentActiveMountainArea]
    //     : this.mountainAreas;
    // },
    findShuttles() {
      return this.shuttles;
    },
    ...mapState(['shuttles', 'mountainAreas', 'mtnAreaNavSections', 'mtnShuttles']),
    ...mapGetters(['currentActiveMountainArea', 'openStatuses', 'getShuttles']),
  },
  methods: {
    // openTrails(trails) {
    //   return trails.filter(trail => trail.ExcludeInTotal !== 'true' && this.openStatuses.indexOf(trail.StatusEnglish) >= 0).length;
    // },
    // totalTrails(trails) {
    //   return trails.filter(trail => trail.ExcludeInTotal !== 'true').length;
    // },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {

    .mountain-area {
      border: .5px solid $brand-blue;
      margin-bottom: 30px;

      .scroll-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;

        @media screen and (min-width: $tablet) {
          overflow-x: hidden;
          overflow-y: hidden;
        }
      }

      .titles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        .swipe {
          color: $brand-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $base-font-bold;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 1.2;
          margin: 0;

          @media screen and (min-width: $tablet) {
            display: none;
          }

          img {
            margin-right: 5px;
          }
        }
      }

      h1 {
        color: $brand-blue;
        display: block;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 1.2;
        margin: 0;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }
    }

    .shuttle-heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      background: #fff;

      .shuttle-name,
      .shuttle-status,
      .shuttle-wait,
      .shuttle-hours,
      .shuttle-message {
        color: $brand-blue;
        display: block;
        font-family: $base-font-bold;
        font-weight: $headline-font-weight;
        font-size: 10px;
        line-height: 1.2;
        margin: 0;
        text-transform: uppercase;
        padding: 10px;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
          letter-spacing: 0.5px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 16px;
        }
      }

      .shuttle-name {
        flex: 1 auto;
        min-width: 150px;
      }

      .shuttle-status {
        text-align: center;
        flex: 0 80px;
        min-width: 80px;
      }

      .shuttle-wait,
      .shuttle-hours {
        text-align: center;
        flex: 0 120px;
        min-width: 120px;

        @media screen and (min-width: $tablet) {
          flex: 0 150px;
          min-width: 150px;
        }
        @media screen and (min-width: $tablet-landscape) {
          flex: 0 180px;
          min-width: 180px;
        }
      }

      .shuttle-message {
        text-align: left;
        flex: 0 200px;
        min-width: 200px;
      }
    }
}
</style>
