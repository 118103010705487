<template>
  <div class="current-weather">
    <div class="forecast-day">
      <div class="weather-details">
        <Icon :weatherZone="weatherZone" />
        <div class="skies-text">{{ weatherZone.Skies }}</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ temperature }}<span class="unit" v-if="temperatureUnit === 'metric'">&ordm;C</span>
            <span class="unit" v-else>&ordm;F</span>
          </span>
        </div>
        <div class="skies-text">Temperature</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ windSpeed }}<span class="unit" v-if="snowbaseUnit === 'metric'">KMH</span>
            <span class="unit" v-else>MPH</span>
          </span>
        </div>
        <div class="skies-text">Wind Speed</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ windDirection }}
          </span>
        </div>
        <div class="skies-text">Wind Direction</div>
      </div>
    </div>
    <div class="forecast-day">
      <div class="weather-details">
        <div class="temperature" id="imperial">
          <span class="metric">
            {{ windGust }}<span class="unit" v-if="snowbaseUnit === 'metric'">KMH</span>
            <span class="unit" v-else>MPH</span>
          </span>
        </div>
        <div class="skies-text">Max Wind Gusts</div>
      </div>
    </div>
  </div>


</template>

<script>
import { mapState } from 'vuex';
import Icon from './Icon.vue';

export default {
  name: 'WeatherCurrent',
  props: ['weatherZone'],
  components: { Icon },
  computed: {
    windSpeed() {
      if (this.weatherZone.WindMph === '--') { return; }

      if (this.weatherZone.WindMph) {
        return this.snowbaseUnit === 'metric'
          ? `${this.weatherZone.WindKph.toString().split('.')[0]}`
          : `${this.weatherZone.WindMph.toString().split('.')[0]}`;
      }
    },
    windGust() {
      //WindGustsMph": 20,
      //"WindGustsKph": 32,
      if (this.weatherZone.WindGustsMph === '--') { return; }

      if (this.weatherZone.WindGustsMph) {
        return this.snowbaseUnit === 'metric'
          ? `${this.weatherZone.WindGustsKph.toString().split('.')[0]}`
          : `${this.weatherZone.WindGustsMph.toString().split('.')[0]}`;
      }
    },
    windDirection() {
      return this.weatherZone.WindDirection;
    },
    temperature() {
      if (this.weatherZone.TempF) {
        return this.temperatureUnit === 'metric'
          ? this.weatherZone.TempC.toString().split('.')[0]
          : this.weatherZone.TempF.toString().split('.')[0];
      }
    },
    temperatureHigh() {
      if (this.weatherZone.TempF) {
        return this.temperatureUnit === 'metric'
          ? this.weatherZone.TempHighC.toString().split('.')[0]
          : this.weatherZone.TempHighF.toString().split('.')[0];
      }
    },
    temperatureLow() {
      if (this.weatherZone.TempF) {
        return this.temperatureUnit === 'metric'
          ? this.weatherZone.TempLowC.toString().split('.')[0]
          : this.weatherZone.TempLowF.toString().split('.')[0];
      }
    },
    tempInMetric() {
      return this.temperatureUnit == 'metric';
    },

    ...mapState(['weather', 'windspeedUnit', 'temperatureUnit', 'snow_report', 'snowbaseUnit']),
  },

  methods: {
    toggleUnits(unit) {
      this.$store.dispatch(
        'setWeatherUnits',
        {
          temperature_Unit: unit,
          windspeed_Unit: unit,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>

#dashboard {

  .current-weather {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;

    .forecast-day {
      flex: 0 25%;
      min-width: 150px;
      width: 20%;
      border-left: .5px solid $brand-blue;
      color: $brand-blue;
      font-size: 12px;
      padding: 15px 20px;
      text-align: left;

      @media screen and (min-width: $tablet) {
        font-size: 14px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 15px;
      }

      &:first-child {
        border-left: 0;
      }

      p {
        color: $brand-blue;
        letter-spacing: -0.3px;
        line-height: 1.2;
        font-size: 12px;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 15px;
        }
      }
    }

  }
  .weather-details{
    font-size: 50px;
    color: $brand-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    //flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    @media screen and (min-width: $tablet) {
      font-size: 65px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 80px;
    }

    .temperature,
    .weather-icon {
      flex: 0 100%;
      width: 100%;
      height: 50px;
      text-align: center;

      @media screen and (min-width: $tablet) {
        height: 65px;

      }
      @media screen and (min-width: $tablet-landscape) {
        height: 80px;
      }
    }
    .weather-icon {
      font-size: 50px;
      font-weight: 400;
      line-height: 1;
      text-align: center;

      @media screen and (min-width: $tablet) {
        font-size: 65px;
      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 80px;
      }
    }

    .temperature {
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: $tablet) {
        font-size: 40px;
        height: 65px;

      }
      @media screen and (min-width: $tablet-landscape) {
        font-size: 50px;
        height: 80px;
      }

      .metric {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 5px;
      }

      .unit {
        margin: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;

        @media screen and (min-width: $tablet) {
          font-size: 18px;
        }
        @media screen and (min-width: $tablet-landscape) {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }


  .day-word {
    color: $brand-blue;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
  }

  .day-temps,
  .wind-speed-text {
    color: $brand-blue;
    font-size: 10px;
    line-height: 1;
    margin: 5px 0;


    @media screen and (min-width: $tablet) {
      font-size: 13px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 14px;
    }
  }

  .skies-text {
    font-family: $font-sans;
    color: $brand-blue;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0px;

    @media screen and (min-width: $tablet) {
      font-size: 13px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 14px;
    }
  }
}
</style>
