<template>
  <div class="quick-links-container" v-if="quickLinks">
    <h1 class="title">
      {{ quickLinksTitle }}
    </h1>

    <a
      :for="quickLink in quickLinks"
      :title="quickLink['title']" class="quick-link"
      :href="quickLink['link']"
      :target="quickLink['target']">
      &middot; <span>{{quickLink['title']}}</span>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'QuickLinks',
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['quickLinks', 'quickLinksTitle']),
  },
};
</script>

<style lang="scss" scoped>
  .quick-links-container {
    background-color: $quicklinks-background;
    font-family: $base-font-regular;
    h1.title {
      color: $white;
      font-family: $headline-font-bold;
      font-size: 18px;
      font-weight: $headline-font-weight;
      line-height: 1.2;
      letter-spacing: 0.42px;
      margin-bottom: 10px;
      text-align: center;
      @media (min-width: $tablet-landscape) {
        text-align: left;
      }
      @media (min-width: 1150px) {
        font-size: 22px;
      }
    }
    a {
      color: $white;
      display: block;
      font-family: $base-font-regular;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0.4px;
      text-decoration: none;
      text-align: center;
      width: 100%;
      span {
        border-bottom: 1px solid transparent;
        padding-bottom: 2px;
        transition: border ease-in-out .25s;
      }
      &:hover {
        span {
          border-bottom: 1px solid $white;
        }
      }
      @media (min-width: $tablet-landscape) {
        font-size: 13px;
        text-align: left;
      }
      @media (min-width: 1150px) {
        font-size: 16px;
      }
    }
  }
</style>
