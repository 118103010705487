<template>
  <div class="forecast-day">
    <div class="day-word tc ttu">
      {{date}}
    </div>
    <div class="weather-icon tc" v-if="iconClass">
      <span :class="iconClass"></span>
    </div>
    <div class="skies-text" v-if="day && day.skies">{{ day.skies }}</div>
    <div class="day-temps tc">
      <span v-if="tempHigh !== '-'">H: {{ tempHigh }}&ordm; / </span>
      <span v-if="tempLow !== '-'">L: {{ tempLow }}&ordm;</span>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapState } from 'vuex';

export default {
  name: 'ForecastDay',
  props: ['day', 'index'],
  computed: {
    tempUnit() {
      return this.temperatureUnit == 'metric' ? 'C' : 'F';
    },
    tempHigh() {
      return this.day
        ? this.temperatureUnit === 'metric'
          ? (this.day.temp_high_c ? this.formatTemp(this.day.temp_high_c) : '-')
          : (this.day.temp_high_f ? this.formatTemp(this.day.temp_high_f) : '-')
        : '-';
    },
    tempLow() {
      return this.day
        ? this.temperatureUnit === 'metric'
          ? (this.day.temp_low_c ? this.formatTemp(this.day.temp_low_c) : '-')
          : (this.day.temp_low_f ? this.formatTemp(this.day.temp_low_f) : '-')
        : '-';
    },
    iconClass() {
      if (this.day && this.day.icon) {
        return `ico-${this.day.icon.replace(/\.svg/g, '')}`;
      }else{
        return false;
      }
    },
    date() {
      if(this.day && this.day.date){
        const dateArray = this.day.date.split('-');
        const day = format(new Date(Number(dateArray[0]), Number(dateArray[1])-1, Number(dateArray[2])), 'E');
        return day;
      }
    },
    ...mapState(['temperatureUnit']),
  },
  methods: {
    formatTemp(temp) {
      return temp.split('.')[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.weather-icon {
  font-size: 60px;
  color: $brand-blue;

  @media screen and (min-width: $tablet) {
    font-size: 65px;
  }
  @media screen and (min-width: $tablet-landscape) {
    font-size: 80px;
  }
}

.forecast-day {
  flex: 0 50%;
  border-left: .5px solid $brand-blue;
  color: $brand-blue;
  font-size: 12px;
  padding: 15px;
  min-width: 150px;
  //
  //@media screen and (min-width: 450px) {
  //  padding: 10px 5px;
  //}

  @media screen and (min-width: $tablet) {
    font-size: 14px;
    padding: 15px;
    min-width: 100px;
    flex:0 20%;
  }
  @media screen and (min-width: $tablet-landscape) {
    font-size: 15px;
  }

  &:first-child {
    border-left: 0;
  }
  &:nth-child(odd){
    @media screen and (max-width: $tablet - 1px) {
      border-left:none;
    }
  }
  &:last-child{
    @media screen and (max-width: $tablet - 1px) {
      border-right: .5px solid $brand-blue; flex:0 calc(50% + 1px);
    }
  }

  p {
    color: $brand-blue;
    letter-spacing: -0.3px;
    line-height: 1.2;
    font-size: 14px;

    @media screen and (min-width: $tablet) {
      font-size: 14px;
    }
    @media screen and (min-width: $tablet-landscape) {
      font-size: 15px;
    }
  }
}
.day-word {
  color: $brand-blue;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
.day-temps {
  color: $brand-blue;
  font-size: 13px;
  line-height: 1;
  padding: 0 5px;

  @media screen and (min-width: $tablet) {
    font-size: 13px;
  }
  @media screen and (min-width: $tablet-landscape) {
    font-size: 14px;
  }
}
.skies-text {
  color: $brand-blue;
  text-align: center;
  font-weight: 700;
  text-transform: none;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 10px;

  @media screen and (min-width: $tablet) {
    font-size: 13px;
  }
  @media screen and (min-width: $tablet-landscape) {
    font-size: 14px;
  }
}

</style>
